import { SaveToLocalStorage } from "../SaveToLocalStorage/SaveToLocalStorage"
import { Navigate, useParams, Routes, Route, useLocation } from 'react-router-dom';
import React from "react";
import CardOfProduct from "../../Containers/CardOfProduct/CardOfProduct";
import BannerImages from "../../Containers/BannerImages/BannerImages.jsx";
import MainPage from "../../Containers/MainPage/MainPage";
import PrivacyPolicy from "../../Containers/PrivacyPolicy/PrivacyPolicy";
import UserArgeement from "../../Containers/UserArgeement/UserArgeement";
import Contact from "../../Containers/Contact/Contact";
import OrderApp from "../../Containers/OrderApp/OrderApp.jsx";
//import SP1 from '../../Containers/SecretPages/sp1';
import DeliveryForm from "../../Containers/deliveryForm/deliveryForm";
import PageError from "../../Containers/PageError/PageError";

import YoReminder from "../../Containers/YoReminder/yoreminder";
import ARPuzzle from "../../Containers/ArPuzzles/arPuzzles";
import ArPuzzleReset from "../../Containers/ArPuzzleReset/ArPuzzleReset";
import ResetPass from "../../Containers/ResetPassword/ResetPass";
//import Help from "../../Containers/Help/Help";

import MatevosPrivacyPolicy from "../../Containers/PresentToMatevos/MatevosPrivacyPolicy";
import MatevosTermsConditions from "../../Containers/PresentToMatevos/MatevosTermsConditions";
import MatevosATermsConditions from "../../Containers/PresentToMatevos/MatevosATermsConditions";

import GuardiansLegionPrivacyPolicy from "../../Containers/GuardiansLegion/GuardiansLegionPrivacyPolicy";
import GuardiansLegionTermsConditions from "../../Containers/GuardiansLegion/GuardiansLegionTermsConditions";
import GuardiansLegionATerms from "../../Containers/GuardiansLegion/GuardiansLegionATerms";
//import { SignLanguageSharp } from "@mui/icons-material";
//import App from "../../App.js";

const RootRouter = ({stateLanguage}) => {
    const  params = useParams();//props.lang;                    
    //const location = useLocation();
    //var stateLanguage  = statelanguage;
    //console.log("statelanguag RootRouter in:", stateLanguage);
    /*const stateLanguage = curLanguage.stateLanguage; */
    //console.log("onClickStyle RootRouter in:", onClickStyle);
    //console.log("curOnClickStyle RootRouter in:", curOnClickStyle);
   
    const isLanguageValid = (lang) => {
      return ((lang === "en") || (lang === "es") || (lang === "ua") || (lang === "de")) ? true : false;
    }           


    function MyLangChangeMainPage () {  
        const  params = useParams();//props.lang; 
        var stateLanguage = params.language;                              
        //console.log("Root MainPage stateLanguage", stateLanguage); 
        /*const s=location.pathname.split('/').length; 
        const curLang =location.pathname.split('/')[s-1];
        console.log("stateLanguage App start input", curLang);*/         
        if ( isLanguageValid(stateLanguage)) {
            if (stateLanguage !== (localStorage.getItem("LANGUAGE")))  
            {  //console.log("stateLanguage !==stored");
                SaveToLocalStorage("LANGUAGE", stateLanguage); //changeLanguage(stateLanguage); 
            }
            return <MainPage stateLanguage={stateLanguage} /> 
          }        
    }

    function MyLangChangeOrderApp () {        
        const  params = useParams();//props.lang; 
        var stateLanguage = params.language;                              
        //console.log("Root OrderApp params.language", stateLanguage); 
        if (isLanguageValid(stateLanguage)) {
            if (stateLanguage !== (localStorage.getItem("LANGUAGE")))  
                {  //console.log("stateLanguage !==stored");  /*if (!isLanguageValid(stateLanguage))  stateLanguage="en";*/
                SaveToLocalStorage("LANGUAGE", stateLanguage);
                }
            return <OrderApp stateLanguage={stateLanguage}  />
        }  
        return <OrderApp stateLanguage={"en"} />
    }   
        /* const  params = useParams();          
        const lang = params.language; 
        if (isLanguageValid(lang)) {
            onSetLanguage(lang);
            return <ArApp stateLanguage={lang} onClickStyle={onClickStyle}  />
        }
        return <ArApp stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle}  />;*/
    

    function MyLangChangeARkozaparty () { 
        const  params = useParams();//props.lang; 
        var stateLanguage = params.language;                              
        //console.log("Root ARkozaparty params.language", stateLanguage); 
        if ( isLanguageValid(stateLanguage)) {
            if (stateLanguage !== (localStorage.getItem("LANGUAGE")))  
            {  console.log("stateLanguage !==stored");  /*if (!isLanguageValid(stateLanguage))  stateLanguage="en";*/
            SaveToLocalStorage("LANGUAGE", stateLanguage);
            }
         return <CardOfProduct stateLanguage={stateLanguage}  />
        }
        return <CardOfProduct stateLanguage={"en"} />
        /*const  params = useParams();          
        const lang = params.language; 
        if (  isLanguageValid(lang)) {
            onSetLanguage(lang);
            return <CardOfProduct stateLanguage={lang}  onClickStyle={onClickStyle}   />
        }
        return <CardOfProduct stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle}    />;*/        
    }

    function MyLangChangeARimages () {                
        const  params = useParams();//props.lang; 
        var stateLanguage = params.language;                              
        //console.log("Root ARimages params.language", stateLanguage); 
        if (isLanguageValid(stateLanguage)) {
            if (stateLanguage !== (localStorage.getItem("LANGUAGE")))  
                {  //console.log("stateLanguage !==stored");  /*if (!isLanguageValid(stateLanguage))  stateLanguage="en";*/
                SaveToLocalStorage("LANGUAGE", stateLanguage);
                }
            return <BannerImages stateLanguage={stateLanguage}  />
        }  
        return <BannerImages stateLanguage={"en"} />  
        /*const  params = useParams();//props.lang; //match.params;            
        const lang = params.language; //.match(); ///\d+/
        if (isLanguageValid(lang)) {
            onSetLanguage(lang);
            return <BannerImages stateLanguage={lang} onClickStyle={onClickStyle} />
        }
        return <BannerImages stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle} />;*/
    }

    function MyLangChangeMatevosPrivacy () {                     
        if (isLanguageValid(stateLanguage))  stateLanguage="en";
            SaveToLocalStorage("LANGUAGE", stateLanguage);
         return <MatevosPrivacyPolicy stateLanguage={stateLanguage}  />
        
        /* const  params = useParams();          
        const lang = params.language; 

        if (isLanguageValid(lang)) {
            onSetLanguage(lang);
            return <MatevosPrivacyPolicy stateLanguage={lang} onClickStyle={onClickStyle} />
        }
        return <MatevosPrivacyPolicy stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle} />;*/
    }
    function MyLangChangeMatevosTerms () {                      
        if (!isLanguageValid(stateLanguage))  stateLanguage="en";
            SaveToLocalStorage("LANGUAGE", stateLanguage);
        return <MatevosTermsConditions stateLanguage={stateLanguage}  />
       
        /*const  params = useParams();          
        const lang = params.language; 

        if (isLanguageValid(lang)) {
            onSetLanguage(lang);
            return <MatevosTermsConditions stateLanguage={lang} onClickStyle={onClickStyle} />
        }
        return <MatevosTermsConditions stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle} />;*/
    }
    function MyLangChangeMatevosATerms () {                   
        if (!isLanguageValid(stateLanguage))  stateLanguage="en";
            SaveToLocalStorage("LANGUAGE", stateLanguage);
         return <MatevosATermsConditions stateLanguage={stateLanguage}  />
       
        /* const  params = useParams();          
        const lang = params.language; 

        if (isLanguageValid(lang)) {
            onSetLanguage(lang);
            return <MatevosATermsConditions stateLanguage={lang} onClickStyle={onClickStyle} />
        }
        return <MatevosATermsConditions stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle} />;*/
    }
    function MyLangChangeGuardiansLegionPrivacy () {                  
        stateLanguage="en";
        if (!isLanguageValid(stateLanguage))  stateLanguage="en";
            SaveToLocalStorage("LANGUAGE", stateLanguage);
         return <GuardiansLegionPrivacyPolicy stateLanguage={stateLanguage}  />
        
        /*const  params = useParams();          
        const lang = params.language; 
 
        if (isLanguageValid(lang)) {
            onSetLanguage(lang);
            return <GuardiansLegionPrivacyPolicy stateLanguage={lang} onClickStyle={onClickStyle} />
        }
        return <GuardiansLegionPrivacyPolicy stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle} />;*/
    }
    function MyLangChangeGuardiansLegionTerms () {                
        if (!isLanguageValid(stateLanguage))  stateLanguage="en";
            SaveToLocalStorage("LANGUAGE", stateLanguage);
         return <GuardiansLegionTermsConditions stateLanguage={stateLanguage}  />
        
        /*const  params = useParams();          
        const lang = params.language; 

        if (isLanguageValid(lang)) {
            onSetLanguage(lang);
            return <GuardiansLegionTermsConditions stateLanguage={lang} onClickStyle={onClickStyle}  />
        }
        return <GuardiansLegionTermsConditions stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle} />;*/
    }
    function MyLangChangeGuardiansLegionATerms () {                       
        if (!isLanguageValid(stateLanguage))  stateLanguage="en";
            SaveToLocalStorage("LANGUAGE", stateLanguage);
         return <GuardiansLegionATerms stateLanguage={stateLanguage}  />
       
        /* const  params = useParams();          
        const lang = params.language; 

        if (isLanguageValid(lang)) {
            onSetLanguage(lang);
            return <GuardiansLegionATerms stateLanguage={lang} onClickStyle={onClickStyle} />
        }
        return <GuardiansLegionATerms stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle} />;*/
    }
    function MyLangChangePrivacity () {                     
        if (!isLanguageValid(stateLanguage))  stateLanguage="en";
            SaveToLocalStorage("LANGUAGE", stateLanguage);
         return <PrivacyPolicy stateLanguage={stateLanguage}  />
       
        /*const  params = useParams();          
        const lang = params.language; 

        if (isLanguageValid(lang)) {
            onSetLanguage(lang);
            return <PrivacyPolicy stateLanguage={lang} onClickStyle={onClickStyle} />
        }
        return <PrivacyPolicy stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle} />;*/
    }
    function MyLangChangeUserArge () {                      
        if (!isLanguageValid(stateLanguage))  stateLanguage="en";
            SaveToLocalStorage("LANGUAGE", stateLanguage);
         return <UserArgeement stateLanguage={stateLanguage}  />
        
        /*const  params = useParams();          
        const lang = params.language; 
 
        if (isLanguageValid(lang)) {
            onSetLanguage(lang);
            return <UserArgeement stateLanguage={lang} onClickStyle={onClickStyle} />
        }
        return <UserArgeement stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle} />;*/
    }

    function MyLangChangeContact () {        
        const  params = useParams();//props.lang; 
        var stateLanguage = params.language;                              
        //console.log("Root Contact params.language", stateLanguage); 
        if (isLanguageValid(stateLanguage)) {
            if (stateLanguage !== (localStorage.getItem("LANGUAGE")))  
                { // console.log("stateLanguage !==stored");  /*if (!isLanguageValid(stateLanguage))  stateLanguage="en";*/
                SaveToLocalStorage("LANGUAGE", stateLanguage);
                }
            return <Contact stateLanguage={stateLanguage}  />
        }
        return <Contact stateLanguage={"en"} />
        /*const  params = useParams();          
        const lang = params.language; 

        if (isLanguageValid(lang)) {
            onSetLanguage(lang);
            return <Contact stateLanguage={lang} onClickStyle={onClickStyle} />
        }
        return <Contact stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle} />;*/
    }

    function MyLangChangeDeliveryForm () {        
        const  params = useParams();//props.lang; 
        var stateLanguage = params.language;                              
        //console.log("Root DeliveryForm params.language", stateLanguage); 
        if (isLanguageValid(stateLanguage)) {
            if (stateLanguage !== (localStorage.getItem("LANGUAGE")))  
                {  //console.log("stateLanguage !==stored");  /*if (!isLanguageValid(stateLanguage))  stateLanguage="en";*/
                SaveToLocalStorage("LANGUAGE", stateLanguage);
                }
            return <DeliveryForm stateLanguage={stateLanguage}  />
        }        
        return <DeliveryForm stateLanguage={"en"} />
    }

    function MyLangChangePageError () {        
        const  params = useParams();//props.lang; 
        var stateLanguage = params.language;                              
        //console.log("Root DeliveryForm params.language", stateLanguage); 
        if (isLanguageValid(stateLanguage)) {
            if (stateLanguage !== (localStorage.getItem("LANGUAGE")))  
                {  //console.log("stateLanguage !==stored");  /*if (!isLanguageValid(stateLanguage))  stateLanguage="en";*/
                SaveToLocalStorage("LANGUAGE", stateLanguage);
                }
            return <PageError stateLanguage={stateLanguage}  />
        }  
        return <PageError stateLanguage={"en"} />  
    }


    return (
        <Routes>
                {/*<Route  path="/" element={<Navigate to="/main"/> }/>  */}{/*exact*/}
                {/*<Route exact path="/"> <Redirect to={{ pathname: "/main" }} /> </Route>*/}
            {/*<Navigate to="/main/"/> } />   <Navigate to="/main/en"/>       changeStyle       */}
            
            <Route path="/main/:language"  element={ <MyLangChangeMainPage   stateLanguage={stateLanguage}/>} /> {/*stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle||" "} */}
                {/*} onClickStyle={onClickStyle} stateLanguage={stateLanguage||"en"} onClickStyle={onClickStyle||" "} onSetLanguage={setStateLanguage}*/}
                {/* <MyLangChangeMainPage onClickStyle={onClickStyle} stateLanguage={stateLanguage}/> } />   */}
            
            <Route  path="/"  element={ <Navigate to="/main/" /> } /> {/*stateLanguage="en"  stateLanguage={stateLanguage || "en"}       onClickStyle={onClickStyle||" "}*/}
            <Route path="*" element={ <Navigate replace to="/main/en"  stateLanguage={stateLanguage}/> }/> {/*stateLanguage="en"        onClickStyle={onClickStyle||" "}*/}

            <Route path="/ar-koza-party/:language" element={ <MyLangChangeARkozaparty  stateLanguage={stateLanguage}/>}/> {/*stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle||" "}  */}
                {/* <MyLangChangeARkozaparty onClickStyle={onClickStyle} stateLanguage={stateLanguage}/> } />*/}            
            <Route path="/orderApp/:language" element={ <MyLangChangeOrderApp stateLanguage={stateLanguage}/>}/>

                {/* <MyLangChangeOrderApp onClickStyle={onClickStyle} stateLanguage={stateLanguage}/> } /> */}


            <Route path="/ar-koza-party/ARimages/:language" element={ <MyLangChangeARimages stateLanguage={stateLanguage}/> } />  {/*stateLanguage={stateLanguage || "en"} onClickStyle={onClickStyle||" "}/*/}          
            
            <Route path="/present-to-matevos/privacy-policy" element={<Navigate to="/present-to-matevos/privacy-policy/en" /> } />
            <Route path="/present-to-matevos/privacy-policy/" element={<Navigate to="/present-to-matevos/privacy-policy/en" /> } />
            <Route path="/present-to-matevos/privacy-policy/:language" element={ <MyLangChangeMatevosPrivacy stateLanguage={stateLanguage}/> } /> {/*onClickStyle={onClickStyle||" "}*/}
            
            <Route path="/present-to-matevos/terms-conditions" element={<Navigate to="/present-to-matevos/terms-conditions/en" /> } />
            <Route path="/present-to-matevos/terms-conditions/" element={<Navigate to="/present-to-matevos/terms-conditions/en" /> } />
            <Route path="/present-to-matevos/terms-conditions/:language" element={ <MyLangChangeMatevosTerms stateLanguage={stateLanguage}/> } /> {/*onClickStyle={onClickStyle||" "}*/}
            
            <Route path="/present-to-matevos/a-terms-conditions" element={<Navigate to="/present-to-matevos/a-terms-conditions/en" /> } />
            <Route path="/present-to-matevos/a-terms-conditions/" element={<Navigate to="/present-to-matevos/a-terms-conditions/en" /> } />
            <Route path="/present-to-matevos/a-terms-conditions/:language" element={ <MyLangChangeMatevosATerms stateLanguage={stateLanguage}/> } /> {/*onClickStyle={onClickStyle||" "}*/}
            
            <Route  path="/guardiansLegion/privacy-policy"  element={ <Navigate to="/guardiansLegion/privacy-policy/en" /> } />
            <Route  path="/guardiansLegion/privacy-policy/"  element={ <Navigate to="/guardiansLegion/privacy-policy/en" /> } />
            <Route path="/guardiansLegion/privacy-policy/:language" element={ <MyLangChangeGuardiansLegionPrivacy stateLanguage={stateLanguage}/> } /> {/*onClickStyle={onClickStyle||" "}*/}
            
            <Route path="/guardiansLegion/terms-conditions" element={<Navigate to="/guardiansLegion/terms-conditions/en" /> } />
            <Route path="/guardiansLegion/terms-conditions/" element={<Navigate to="/guardiansLegion/terms-conditions/en" /> } />
            <Route path="/guardiansLegion/terms-conditions/:language" element={ <MyLangChangeGuardiansLegionTerms stateLanguage={stateLanguage}/> } /> {/*onClickStyle={onClickStyle||" "}*/}
            
            <Route path="/guardiansLegion/a-terms-conditions" element={<Navigate to="/guardiansLegion/a-terms-conditions/en" /> } />
            <Route path="/guardiansLegion/a-terms-conditions/" element={<Navigate to="/guardiansLegion/a-terms-conditions/en" /> } />
            <Route path="/guardiansLegion/a-terms-conditions/:language" element={ <MyLangChangeGuardiansLegionATerms stateLanguage={stateLanguage}/> } /> {/*onClickStyle={onClickStyle||" "}*/}
            
            
            <Route path="/privacy-policy/:language" element={ <MyLangChangePrivacity stateLanguage={stateLanguage}/> } /> {/*onClickStyle={onClickStyle||" "}*/}
            <Route path="/user-agreement/:language" element={ <MyLangChangeUserArge stateLanguage={stateLanguage}/> } /> {/*onClickStyle={onClickStyle||" "}*/}
            
            
            <Route path="/contact" element={<Navigate to="/contact/en" /> } />
            <Route path="/contact/" element={<Navigate to="/contact/en" /> } />
            <Route path="/contact/:language" element={ <MyLangChangeContact stateLanguage={stateLanguage}/> } /> {/*onClickStyle={onClickStyle||" "}*/}
            
            <Route path="/delivery-form/:language" element={ <MyLangChangeDeliveryForm stateLanguage={stateLanguage}/> } /> {/*onClickStyle={onClickStyle||" "}*/}
            <Route path="/error/:language" element={ <MyLangChangePageError stateLanguage={stateLanguage}/> } />  {/*onClickStyle={onClickStyle||" "}*/}

            <Route path="/YoReminder/ResetPassword/:language" element={<ResetPass stateLanguage={stateLanguage}/>} /> {/*}stateLanguage={stateLanguage} onClickStyle={onClickStyle||" "} */}
            <Route path="/YoReminder/" element={<YoReminder stateLanguage={stateLanguage}/>} />  {/*}stateLanguage={stateLanguage} onClickStyle={onClickStyle||" "} */}
            <Route path="/ARPuzzle/ResetPassword/:language" element={<ArPuzzleReset stateLanguage={stateLanguage}/>} />  {/*}stateLanguage={stateLanguage} onClickStyle={onClickStyle||" "} */}
            <Route path="/ARPuzzle/:language" element={<ARPuzzle stateLanguage={stateLanguage}/>} />  {/*}stateLanguage={stateLanguage} onClickStyle={onClickStyle||" "} */}

            {/*<Route exact path="/main#:block?">
                const { block } = match.params;
                if (!block) {
                <Redirect to={{ pathname: "/main#",params: block }} />
                }                
            </Route>*/}

        </Routes>
    );
};

export default RootRouter;