import React from 'react'
import { Link } from 'react-router-dom';
import * as emailjs from 'emailjs-com';
import Language from "./OrderLanguage";
import "./OrderApp.css";
import unicornLogo from '../../images/unicorn_logo.png';
import cupAR from '../../images/stakanMishkaAr.webp';
import catAR from '../../images/catAr.webp';
//import  Email from './smtp.js';
//import  ServerSMTP  from './ServerSMTP'


/*async function MojSend(InfoEmail) {
     
        let transporter = nodemailer.createTransport({
            host: "freemail.freehost.com.ua",
            port: 465, //993, //
            secure: true,

              auth: {
                user: "studio@aforehand.com.ua", //"kozina4common@gmail.com", //
                pass: "Studio202", //"rnjnfv0407", //
                    } ,
            debug: true, // show debug output
            logger: true         
            }) ;  

        let optionsMail = {
        from: InfoEmail.mailfrom,
        to: "studio@aforehand.com.ua",
        subject: "Moj check",
        text: "Сообщение: " + InfoEmail.message
        //html: "Сообщение: " + Info.message //emailHtml,
        };   
        var res = await transporter.sendMail(optionsMail);
        console.log('Message sent successfully as %s');                    

}*/

export default class OrderApp extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            stateLanguage: { ...props },
            IsSended: false,
            isEmailOk: false,
            isNameOk: false,
            isMessageOk: false,
            isTermsOfUseOk: false,
            InfoEmail: {
                username: "",
                mailfrom: "",
                message: "",
                termsOfUse: false
            } ,
            sendFormPropertis: {
                service: "service_gf42tma",
                template: "template_oeasndb",
                selector: "#mailform",
                userId: "user_zr3jNsGItXGHKG7MIfu8J"
            }
        };
    }

    componentDidUpdate() {
        //console.log(this.state);
        window.scrollTo({top:0,  behavior: "smooth"}); 
    }

    componentDidMount() {
        //window.scrollTo(0, 0);
        
        this.setState({
            IsSended: false,
            isEmailOk: false,
            isNameOk: false,
            isMessageOk: false,
            isTermsOfUseOk: false,
            stateLanguage: this.props.stateLanguage
        });
        window.scrollTo({top:0,  behavior: "smooth"}); 
    }
   
    onFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        var InfoEmail = this.state.InfoEmail;
        InfoEmail[name] = value;
        this.setState({ InfoEmail });
        window.scrollTo({top:0,  behavior: "smooth"}); 
    }
   
//moj
/*
// async..await is not allowed in global scope, must use a wrapper
async function main() {
  // send mail with defined transport object
  const info = await transporter.sendMail({
    from: '"Maddison Foo Koch " <maddison53@ethereal.email>', // sender address
    to: "bar@example.com, baz@example.com", // list of receivers
    subject: "Hello ✔", // Subject line
    text: "Hello world?", // plain text body
    html: "<b>Hello world?</b>", // html body
  });
  //console.log("Message sent: %s", info.messageId);  
}
main().catch(console.error);
*/

    //was 
    checkFields = () => {
        var inputEmail = document.getElementById("exampleInputEmail1")?.value;
        var inputName = document.getElementById("exampleInputEmail2")?.value;
        var message = document.getElementById("exampleTextarea")?.value;
        var termsOfUse = document.getElementById("termsOfUse")?.checked;

        var InfoEmail = {
            mailfrom: inputEmail,
            username: inputName,
            message
        };
        var isEmailOk = inputEmail && (inputEmail.indexOf('@') > -1) && (inputEmail.indexOf('.') > -1);
        var isNameOk = inputName && true;
        var isMessageOk = message && true;
        var isTermsOfUseOk = termsOfUse && true;
            //console.log("checkFields isTermsOfUseOk:", isTermsOfUseOk);
        this.setState({ isEmailOk, isMessageOk, isNameOk, isTermsOfUseOk, InfoEmail });
        }

    sendMessege = (e) => {
        const { service, template, userId } = this.state.sendFormPropertis;
        const { InfoEmail } = this.state;
        var templateParams = {
            head: "Aforehand Studio Заказ приложения",
            username: InfoEmail.username,
            mailfrom: InfoEmail.mailfrom,
            message1: "Сообщение: " + InfoEmail.message
        };
        /*console.log("emailjs service: ", service);
        console.log("emailjs template: ", template);
        console.log("emailjs templateParams: ", templateParams);*/
        emailjs .send(
                service,
                template,
                templateParams,
                userId
            );
            /*emailjs.send("service_gf42tma","template_oeasndb",{
username: "moj",
message: "hello Dolly",
});*/
        this.setState({ IsSended: true });
    };


    /*sendMessege =  () => {      
        const { InfoEmail } = this.state;*/
        //const nodemailer = require("nodemailer");
        /*const transporter = nodemailer.createTransport({
            host: "smtp.aforehand.com.ua",//"freemail.freehost.com.ua",
            port: 465, //993, //
            secure: true,*/
                        /*dkim: {
                domainName: "aforehand.com.ua",
                keySelector: "mojsender1",
                privateKey: "-----BEGIN RSA PRIVATE KEY-----\nMIICXAIBAAKBgQCvkRwy+rypdVOyUzc6iJ80jN9CMg/u76Pf6/MYyIkucwz/HaqS\nBC2+vKuayiUI3BRXwi42JEkxg4cbxkBunthQ/9roiGSjn+uox6e4o6VJap6cgwPx\nnT7rUSjxudayuYWmFUz88bEGjRvI1tYL4zorzjryRlg0UrTRzmRfsOiLxQIDAQAB\nAoGARYcwJbhKssl2Je7ytkmY0xTe7bYpvTRGL82fbjcsrKp/gSDchgsFmr3ZL+nS\nFNojbEXIS0S/f85Y2VT/+08I1xJ/bjISLebEx9vkjg7tOieFbRIj/8NTVGBUmKFW\njFMDTkLTV1TeEGfZZoNBOmWPFiUGMpaGR3isX3whaaQPqsECQQDoMPf33uHMRFle\ntPGY4H9tHw9EmPTkqFzuTxWrs7hvOgGd0aaX22eloRpFKCckXaX3s7jxY/qbcDxA\nIGg14iu9AkEAwZG/T4SDvGNxQVT/8MFsu3m05lhYjtCYuf8JMRTlUxPmiCe7Wifz\nFMFNP461UEaZU8/2RZ4OmkAu+CHwhaUcqQJABWj2riDUVAUXq/w9UNmSPoGlwmxp\n8FX37FmNYLXMS1MnrHyxDALBtHs/eHMKDm/cKRxX58/zi+GelcPria3eZQJAcek6\nQypBeBCxKnhB8cVyHlV+Dnv5CXpaFkKufxLD7YLtjDyNdX3C//jNY9SwgKA7ROZC\nEC/2efHBckZOy+chmQJBALh/b8PEmftrhMmcqs3Kq853UdO74xwGsV3UxAmVfqyR\npS68PDzOovTlNxzt+/rHXeUCJstMa6nE7VmTu7meOwI=\n-----END RSA PRIVATE KEY-----",
                //-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBg...",
              },*/
                        //Service: "Gmail",
            /* auth: {
                user: "kozina4common@gmail.com", //"studio@aforehand.com.ua",
                pass: "rnjnfv0407", //"Studio202",
                    } ,
            debug: true, // show debug output
            logger: true         
            }) ; */
                            /* transporter.verify(function(error, success) {
                if (error) {
                      console.log("Connection error:", error);
                } else {
                      console.log('Server is ready to take our messages');
                }
              });    */     
            
            /*const options = {
              from: InfoEmail.mailfrom,
              to: "studio@aforehand.com.ua",
              subject: "Moj check",
              text: "Сообщение: " + InfoEmail.message
                        //html: "Сообщение: " + Info.message //emailHtml,
            };                       
        try {            //await 
            transporter.sendMail(options, function(error, info){
                if (error) { 
                    this.setState({ IsSended: false }); //  console.log("Message not sent: %s", Info.mailfrom );
                } 
                else{ 
                    this.setState({ IsSended: true }); //console.log("Email sent successfully", Info.mailfrom + Info.username+Info.message);
                }
              });
            } catch (error) {
                console.error('Error sending email:', error);
            }*/

            // const messageForSend= InfoEmail;             
            //ServerSMTP();            
            /*Email.send({
                Host : "freemail.freehost.com.ua",
                Username : "studio@aforehand.com.ua",
                Password : "Studio202",
                To : 'studio@aforehand.com.ua',
                From : "studio@aforehand.com.ua",
                Subject : "This is the subject1",
                Body : "And this is the body2"
            }).then(
              message => alert(message)
            );*/

            //subject,to,body
            //test.sendEmail("From OrderApp","studio@aforehand.com.ua",InfoEmail);

        /*this.setState({ IsSended: true }); //was
        }*/
    


    render() {
        //window.scrollTo(0, 0); 
        const [languages] = Language;
        const { stateLanguage } = this.props;
        //var stateLanguage   = this.props.stateLanguage; 
        /*  window.scrollTo({top:0,  behavior: "smooth"}); */
        /*console.log ("stateLanguage ArApp", stateLanguage); */

        if (this.state.IsSended) {
            const notification1 = stateLanguage === "en" 
            ? "Message sent successfully!" 
            : stateLanguage === "es" 
                ? "¡Mensaje enviado con éxito!" 
                : stateLanguage === "ua" 
                    ? "Повідомлення успішно надіслано!" 
                    : "Nachricht erfolgreich gesendet!"
            
            const notification2 = stateLanguage === "en" 
                ? " You will be contacted shortly." 
                : stateLanguage === "es" 
                    ? " Nos comunicaremos con usted en breve." 
                    :stateLanguage === "ua" 
                    ? " Незабаром з вами зв'яжуться." 
                    : " Sie werden in Kürze kontaktiert.";
            
            const buttontext = stateLanguage === "en" 
            ? "Return to the main page" 
            : stateLanguage === "es" 
                ? "Regresar a la pagina principal" 
                : stateLanguage === "ua" 
                    ? "Повернення до головної сторінки" 
                    : "Zurück zur Hauptseite";
            return (

                <main className="main">
                    <div className="container">                    
                    <div className="row">
                        <div className=" alert">
                            <br />
                            <i style={{ color: "black" }}>{notification1}{notification2}</i>
                            <br />
                            <br />
                            <div>
                                <Link className="col-12" to={"/main/"+stateLanguage} style={{ float: 'right' }} >
                                    <button className="btn btn-info buttonStyle" >{buttontext}</button>
                                </Link>
                            </div>
                            <br />
                        </div>
                    </div>
                    </div>
                </main>
            );
        } 

        //const emailplaceholder = stateLanguage && languages[stateLanguage].emailplaceholder;
        /*    console.log ("emailplaceholder orderApp: ",stateLanguage && languages[stateLanguage].emailplaceholder);
        var nameplaceholder = stateLanguage && languages[stateLanguage].nameplaceholder;
            console.log ("emailplaceholder orderApp: ",nameplaceholder);
        var textplaceholder = stateLanguage && languages[stateLanguage].textplaceholder;
            console.log ("emailplaceholder orderApp: ",textplaceholder);
        var warningtext = stateLanguage && languages[stateLanguage].warningtext;
            console.log ("emailplaceholder orderApp: ",warningtext);*/
        const emailplaceholder =stateLanguage === "en" ? "Enter your email" :
                                 stateLanguage === "es" ? "Introduce tu correo electrónico" :
                                stateLanguage === "ua" ? "Введіть свою електронну пошту" :
                                stateLanguage === "de" ?"Geben sie ihre E-Mail Adresse ein"
                                :"Enter your email";
        const nameplaceholder = stateLanguage === "en" ? "Enter your name" :
                                stateLanguage === "es" ? "Introduzca su nombre" :
                                stateLanguage === "ua" ? "Введіть своє ім'я" :
                                stateLanguage === "de" ?"Gib deinen Namen ein"
                                :"Enter your name";
        const textplaceholder = stateLanguage === "en" ? "Any coments" :
                                stateLanguage === "es" ? "Comentarios" :
                                stateLanguage === "ua" ? "Коментарі" :
                                stateLanguage === "de" ? "Kommentare"
                                :"Any coments";
        const warningtext = stateLanguage === "en" ? "Required field" :
                             stateLanguage === "es" ? "Campo requerido" :
                            stateLanguage === "ua" ? "Обов'язкове поле" :
                            stateLanguage === "de" ?"Pflichtfeld"
                            :"Required field"; 
        var content;
        const scrWidth = window.screen.availWidth;
        if (scrWidth > 479) {
            content =
                <form id="mailform" className="col-10 justify-content-center cont2">
            
                    <div className='col-11' style={{ margin: '0 auto' }}>
                        <div className='col-12' style={{ margin: '0 auto' }}>
                            <div className='col-12' style={{ margin: '0 auto' }}>
                                <div className='col-12' style={{ margin: '0 auto' }}>
                                    <div className='col-12' style={{ margin: '0 auto' }}>
                                        <div className='col-12' style={{ margin: '0 auto' }}>
                                            <div className='col-12' style={{ margin: '0 auto' }}>
                                                <div className='col-12' style={{ margin: '0 auto' }}>

                                                    <div className={this.state.isEmailOk ? "form-group has-success" : "form-group has-danger"}>
                                                        {stateLanguage && languages[stateLanguage].emailText}

                                                        <input
                                                            type="email"
                                                            /*id="emailField"*/
                                                            className={this.state.isEmailOk ? "form-control is-valid" : "form-control is-invalid"}
                                                            id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                            placeholder={emailplaceholder}
                                                            name="mailfrom"
                                                            onChange={() => { this.checkFields() }}
                                                        />
                                                        {!this.state.isEmailOk ? <div className="invalid-feedback">
                                                            {warningtext}</div> : <React.Fragment></React.Fragment>}

                                                        {stateLanguage && languages[stateLanguage].alertText}
                                                        {stateLanguage && languages[stateLanguage].nameText}

                                                        <input
                                                            type="text"
                                                            className={this.state.isNameOk ? "form-control is-valid" : "form-control is-invalid"}
                                                            id="exampleInputEmail2"
                                                            aria-describedby="emailHelp"
                                                            placeholder={nameplaceholder}     /*nameplaceholder}*/
                                                            name="username"
                                                            onChange={() => { this.checkFields() }}
                                                        />
                                                        {!this.state.isNameOk ? <div className="invalid-feedback">
                                                            {stateLanguage && languages[stateLanguage].warningtext}</div> : <React.Fragment></React.Fragment>}


                                                        {stateLanguage && languages[stateLanguage].descriptionText}

                                                        <textarea id="exampleTextarea" rows="3"
                                                            name="message"
                                                            placeholder={textplaceholder} /*textplaceholder}*/
                                                            className={this.state.isMessageOk ? "form-control is-valid" : "form-control is-invalid"}
                                                            onChange={() => { this.checkFields() }}
                                                        />
                                                        {!this.state.isMessageOk ? <div className="invalid-feedback"> 
                                                            {stateLanguage && languages[stateLanguage].warningtext} </div> 
                                                            : <React.Fragment>  </React.Fragment>}
                                                        <div className="form-check" style={{ paddingTop: '3%', marginLeft: '0.9rem' }}>
                                                            <input className={this.state.isTermsOfUseOk ? "form-check-input" : "form-check-input wrongCheckBox"} 
                                                            type="checkbox" id="termsOfUse" name="username" onChange={() => { this.checkFields() }}
                                                            />
                                                            <div style={{ fontSize: "1em" }}>
                                                                {stateLanguage && languages[stateLanguage].privacyText}
                                                            </div>
                                                        </div>


                                                    </div>
                                                    
                                                    { this.state.isEmailOk && this.state.isNameOk && this.state.isTermsOfUseOk 
                                                           ? <button style={{ fontSize: "1.25em" }} type="submit" className="btn btn-outline-success" 
                                                            onClick={this.sendMessege} >      {/*}() =>*/}                                                     
                                                                {stateLanguage && languages[stateLanguage].buttonText}
                                                            </button>
                                                            : <button style={{ fontSize: "1.25em", marginBottom: "1.5em"}} type="submit" className="btn btn-outline-success" disabled>
                                                                {stateLanguage && languages[stateLanguage].buttonText}
                                                            </button>
                                                            }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
        }
        else {
            content =
                <div className="row">
                    <form id="mailform" className="col-12 justify-content-center cont1">
                        <div className="form-group row description_text" style={{ paddingTop: '2em', marginBottom: '0%' }}>
                            <hr />
                            {stateLanguage === "en"
                                ? <div>
                                    <p className="col-form-label">To order an AR application for advertising or your business, fill out and send the form.</p>
                                    <p className="col-form-label"> We will contact you within 24 hours.</p>
                                </div>
                                : stateLanguage === "es"
                                    ? <div>
                                        <p className="col-form-label">Para solicitar una solicitud de RA para publicidad o su negocio, complete y envíe el formulario.</p>
                                        <p className="col-form-label">Nos comunicaremos con usted en un plazo de 24 horas.</p>
                                    </div>
                                    : stateLanguage === "ua"
                                        ? <div>
                                            <p className="col-form-label">Для замовлення AR додатка для реклами або свого бізнесу, заповніть та відправте форму.</p>
                                            <p className="col-form-label"> Ми зв'яжемося з вами протягом 24 годин.</p>
                                        </div>
                                        : <div>
                                            <p className="col-form-label">Um eine AR-Anwendung für Werbung oder Ihr Unternehmen zu bestellen, füllen Sie das Formular aus und senden Sie es ab.</p>
                                            <p className="col-form-label"> Wir werden Sie innerhalb von 24 Stunden kontaktieren.</p>
                                        </div>
                            }
                        </div>

                        <div className={this.state.isEmailOk ? "form-group has-success" : "form-group has-danger"}>

                            {stateLanguage === "en"
                                ? <label htmlFor="exampleInputEmail1" className="form-label mt-4">Email address</label>
                                : stateLanguage === "es"
                                    ? <label htmlFor="exampleInputEmail1" className="form-label mt-4">Correo electrónico</label>
                                    : stateLanguage === "ua"
                                        ? <label htmlFor="exampleInputEmail1" className="form-label mt-4">Електронна пошта</label>
                                        : <label htmlFor="exampleInputEmail1" className="form-label mt-4">E-Mail-Addresse</label>}


                            <input
                                type="email"
                                /*id="emailField"*/
                                className={this.state.isEmailOk ? "form-control is-valid" : "form-control is-invalid"}
                                id="exampleInputEmail1" aria-describedby="emailHelp" 
                                placeholder={emailplaceholder}
                                name="mailfrom"
                                onChange={() => { this.checkFields() }}
                            />

                            {!this.state.isEmailOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}

                            {stateLanguage === "en"
                                ? <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                                : stateLanguage === "es"
                                    ? <small id="emailHelp" className="form-text text-muted">Nunca compartiremos su correo electrónico con nadie más.</small>
                                    : stateLanguage === "ua"
                                        ? <small id="emailHelp" className="form-text text-muted">Ми ніколи не поділимось вашою електронною поштою з кимось іншим.</small>
                                        : <small id="emailHelp" className="form-text text-muted">Wir werden Ihre E-Mail niemals mit anderen teilen.</small>}

                            {stateLanguage === "en"
                                ? <label htmlFor="exampleInputEmail1" className="form-label mt-4">Name</label>
                                : stateLanguage === "es"
                                    ? <label htmlFor="exampleInputEmail1" className="form-label mt-4">Nombre</label>
                                    : stateLanguage === "ua"
                                        ? <label htmlFor="exampleInputEmail1" className="form-label mt-4">Ім'я</label>
                                        : <label htmlFor="exampleInputEmail1" className="form-label mt-4">Name</label>}
                            <input
                                type="text"
                                className={this.state.isNameOk ? "form-control is-valid" : "form-control is-invalid"}
                                id="exampleInputEmail2"
                                aria-describedby="emailHelp"
                                placeholder={nameplaceholder}
                                name="username"
                                onChange={() => { this.checkFields() }}
                            />
                            {!this.state.isNameOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}


                            {stateLanguage === "en"
                                ? <label htmlFor="exampleTextarea" className="form-label mt-4">Text message</label>
                                : stateLanguage === "es"
                                    ? <label htmlFor="exampleTextarea" className="form-label mt-4">Mensaje de texto</label>
                                    : stateLanguage === "ua"
                                        ? <label htmlFor="exampleTextarea" className="form-label mt-4">Текстове повідомлення</label>
                                        : <label htmlFor="exampleTextarea" className="form-label mt-4">Textnachricht</label>}

                            <textarea id="exampleTextarea" rows="3"
                                name="message"
                                className={this.state.isMessageOk ? "form-control is-valid" : "form-control is-invalid"}
                                onChange={() => { this.checkFields() }}
                            />
                            {!this.state.isMessageOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}
                            <div className="form-check" style={{ paddingTop: '3%', marginLeft: '20px' }}>
                                <input className={this.state.isTermsOfUseOk ? "form-check-input" : "form-check-input wrongCheckBox"} type="checkbox" id="termsOfUse"
                                    name="username" onChange={() => { this.checkFields() }}
                                />

                                {stateLanguage === "en"
                                    ? <label className="form-check-label" style={{ fontSize: "16px" }} htmlFor="termsOfUse">
                                        I accept <Link to={'/privacy-police/'+stateLanguage} style={{ color: 'purple' }}>Privacy Policy, </Link>
                                        <br />
                                        as well as <Link to={'/user-argeement/'+stateLanguage} style={{ color: 'purple' }}>Term of Use</Link>.
                                    </label>
                                    : stateLanguage === "es"
                                        ? <label className="form-check-label" style={{ fontSize: "16px" }} htmlFor="termsOfUse">
                                            Estoy de acuerdo con la <Link to={'/privacy-police/'+stateLanguage} style={{ color: 'purple' }}>Política de privacidad </Link>
                                            <br />
                                            y los <Link to={'/user-argeement/'+stateLanguage} style={{ color: 'purple' }}>Términos de uso</Link>.

                                        </label>
                                        : stateLanguage === "ua"
                                            ? <label className="form-check-label" style={{ fontSize: "16px" }} htmlFor="termsOfUse">
                                                Я згоден з <Link to={'/privacy-police/'+stateLanguage}  style={{ color: 'purple' }}>Політикою конфіденційності,</Link>
                                                <br />
                                                а також <Link to={'/user-argeement/'+stateLanguage} style={{ color: 'purple' }}>Умовами використання</Link>.
                                            </label>
                                            : <label className="form-check-label" style={{ fontSize: "16px" }} htmlFor="termsOfUse">
                                                Ich stimme <Link to={'/privacy-police/'+stateLanguage} style={{ color: 'purple' }}>den Datenschutzbestimmungen</Link>
                                                <br />
                                                und <Link to={'/user-argeement/'+stateLanguage} style={{ color: 'purple' }}>Nutzungsbedingungen</Link> zu.
                                            </label>}
                            </div>
                        </div>

                        {
                            this.state.isEmailOk && this.state.isNameOk && this.state.isTermsOfUseOk ?
                                <button type="submit" className="btn btn-outline-success" onClick={() =>this.sendMessege} >
                                    {stateLanguage && languages[stateLanguage].buttonText}
                                </button>
                                : <button type="submit" className="btn btn-outline-success" disabled>
                                    {stateLanguage && languages[stateLanguage].buttonText}
                                </button>
                        }
                    </form >
                </div>
        }


        var offerHtml = (!window.mobileCheck()) ? <React.Fragment>
            <div className='col-10' style={{ margin: '0 auto' }}>
                <div className='row'>
                    <div className='row col-12 np' style={{ textAlign: 'center', paddingTop:"25px", paddingBottom:"25px" }}>
                       {/*} <p><br /></p>*/}
                        <h5 style={{textTransform: "uppercase",color: "purple", }}>
                            {stateLanguage && languages[stateLanguage].helpUa}
                        </h5>
                        {/*<p><br /></p>*/}
                    </div>

                    <div className='row col-12 np'>
                        <div className='col-12 np'>
                            {/* p-> text-indent:20px;    style={{textIndent:'20px'}} */}
                            <p>
                                {stateLanguage && languages[stateLanguage].text2}

                            </p>
                            <p>
                                {stateLanguage && languages[stateLanguage].text3}

                            </p>
                        </div>

                    </div>
                    <div className='row col-12 np' style={{ marginBottom: '0em' }}>
                        <div className='col np' style={{ display: 'flex' }}>
                            <img className='imgPadLeft imgCenter' src={unicornLogo} alt='unicorn' />
                        </div>
                        <div className='col-10 np' style={{ paddingRight: '1%' }}>

                            <p style={{}}>
                                {stateLanguage && languages[stateLanguage].text4}


                            </p>
                        </div>


                    </div>
                    <div className='row col-12 np'>
                        <div className='col np' style={{ paddingLeft: '2.24em', display: 'flex' }}>
                            <p style={{ margin: 'auto' }}>
                                {stateLanguage && languages[stateLanguage].text5}

                            </p>
                        </div>
                        <div className='col-5 np' style={{ display: 'flex' }}>
                            <img className='imgPadRight2' style={{ justifySelf: 'center' }} src={cupAR} alt="cupAr" />
                        </div>

                    </div>

                    <div className='row col-12 np' style={{ }} >
                        <div className='col-4 np'>
                            <img className='imgPadLeft' src={catAR} alt='catAr' style={{ float: 'right', width: '100%' }} />
                        </div>
                        <div className='col np' style={{ display: 'flex' }}>
                            <div style={{ margin: 'auto' }}>
                                <p>
                                    {stateLanguage && languages[stateLanguage].text6}
                                </p>
                                <p>
                                    {stateLanguage && languages[stateLanguage].text7}

                                </p>
                                <p>
                                    {stateLanguage && languages[stateLanguage].text8}
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className='row col-12 np'>
                        <div className='col-12 np' style={{paddingTop:'1em'}}>
                            <p>
                                {stateLanguage && languages[stateLanguage].text9}

                            </p>

                        </div>
                    </div>
                    <div className="form-group row description-text" style={{ marginTop:'0', paddingLeft: '0', marginBottom: '0%' }}>
                        {stateLanguage && languages[stateLanguage].directions}
                    </div>
                </div>
            </div>
        </React.Fragment >
            : <React.Fragment>
                <div className='col-12' style={{ margin: '50px auto 0' }} >
                    <div className='row'>
                        <div className='row col-12 np' style={{ textAlign: 'center' }}>
                            {/*<p><br /></p>*/}
                            <h4 style={{textTransform: "uppercase"}}>
                                {stateLanguage && languages[stateLanguage].helpUa}
                            </h4>
                           {/*} <p><br /></p>*/}
                        </div>

                        <div className='row col-12 np'>
                            <div className='col-12 np' style={{ marginBottom: '1.5em' }}>
                                {/* p-> text-indent:20px;    style={{textIndent:'20px'}} */}
                                <p>
                                    {stateLanguage && languages[stateLanguage].text2}
                                </p>
                                <p>
                                    {stateLanguage && languages[stateLanguage].text3}
                                </p>
                            </div>

                        </div>
                        <div className='col-12 np' >
                            <img className='center' style={{ width: '30%' }} src={unicornLogo} alt='unicorn' />
                        </div>
                        <div className='col-12 np' style={{ marginBottom: '1.5em' }}>

                            <p style={{}}>
                                {stateLanguage && languages[stateLanguage].text4}

                            </p>
                        </div>




                        <div className='col-12 np'>
                            <img className='center' style={{ width: '80%' }} src={cupAR} alt="cupAr" />
                        </div>
                        <div className='col-12 np' style={{ marginBottom: '1.5em' }}>
                            <p style={{}}>
                                {stateLanguage && languages[stateLanguage].text5}
                            </p>

                        </div>


                        <div className='col-12 np'>
                            <img className='' src={catAR} alt='catAr' style={{ marginBottom: '1.5em', width: '100%' }} />
                        </div>

                        <div className='col-12 np' style={{}}>
                            <p>
                                {stateLanguage && languages[stateLanguage].text6}
                            </p>
                            <p>
                                {stateLanguage && languages[stateLanguage].text7}

                            </p>
                            <p>
                                {stateLanguage && languages[stateLanguage].text8}
                            </p>
                        </div>




                        <div className='row col-12 np'>
                            <div className='col-12 np'>
                                <p>
                                    {stateLanguage && languages[stateLanguage].text9}

                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment >;
        
        return (
            <main className="main">
                <div className="container">
                    <div className='row' id="offerText">
                        {offerHtml}
                    </div>

                    <div className="row">
                        <div className="col-1 cont2"></div>
                        <div className="col-1 cont1"></div>

                        {content}
                        
                        <div className="col-1 cont2"></div>
                        <div className="col-1 cont1"></div>
                        
                    </div>
                </div>

            </main>
        );
    }
}

