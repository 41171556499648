import React from "react";

import { blue, red, pink } from "@mui/material/colors";

//Language
import Language from "./Language";

//Router
import { Link, useNavigate, useLocation } from "react-router-dom";
import { SaveToLocalStorage } from "../SaveToLocalStorage/SaveToLocalStorage"
//Images
import logoMob from "../../images/AforehandStudio_logo_v2.webp";
//import gplayBig from "../../images/GooglePlay.png";

//Icons
//import FacebookIcon from "@mui/icons/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
//import InstagramIcon from "@mui/icons-material/Instagram";

//Styles
import "./Footer.css";


const checkPathNameForLinkBySwitchingLanguagesF = (l) => {
  var arr = [ 'en', 'ua', 'es', 'de']; //'EN', 'UA', 'ES', 'DE',
  for (var i = 0; i < arr.length; i++) {
      if (l === arr[i]) return true;
  }
  return false;
}


const Footer = ({ stateLanguage }) => {

  const [languages] = Language;
  let navigate = useNavigate();
  const locationD = useLocation();

  const pathNameLangF = (NewLG) => {           
    //console.log("NewLG", NewLG);
    var s=locationD.pathname.split('/').length; 
        //console.log("length s", s);           
        //console.log("checkPathNameForLinkBySwitchingLanguages"  , checkPathNameForLinkBySwitchingLanguages(locationD.pathname.split('/')[s-1]));
    var oldLang =locationD.pathname.split('/')[s-1];
        //console.log("locationD.pathname",locationD.pathname);
        //console.log("splitLast",oldLang);
        
        if ( checkPathNameForLinkBySwitchingLanguagesF(oldLang) ) { 
            var pathnameMy =locationD.pathname.replace( oldLang,"") ;                
            //console.log("pathnameMy true", pathnameMy);
            var pathnameNew = pathnameMy+NewLG; 
            //console.log("pathnameNew true", pathnameNew);
        }
        else {  var pathnameNew = locationD.pathname + "/"+ NewLG;   }
        return pathnameNew;
    }

  const GoOrdertAppF=(curLang) =>{                
     SaveToLocalStorage ("ONCLICKSTYLE", " ");         
     var pathOrderLang = pathNameLangF(curLang); 
     navigate (pathOrderLang); 
 }


  const normalVers = <React.Fragment>
    <div className="row" style={{marginBottom:"0.5em"}}>
      {/*Logo*/}
      <div className="col-2">
        <Link to={"/main/"+stateLanguage} className="footer-logo" onClick={()=>{ window.scrollTo(0,0)}}>
          <img src={logoMob} alt="Logo"></img>
        </Link>
      </div>
      {/*Center */}
      <div className="col-8" style={{ marginTop: "0.7em" }}>
              {/*row1        <div className="footer-right">*/}        
          <div className="row" >
            <div className="col-10">
              <div className="row" >
                <div className="col-4" style={{ textAlign: "left", marginLeft: "9.5%", marginTop:"1em", fontSize:"1.05em" }}>
                  < Link to={"/contact/"+stateLanguage} style={{ color: "purple" }}
                    data-content={stateLanguage && languages[stateLanguage].link3}>
                    {stateLanguage && languages[stateLanguage].link3}
                  </Link>
                </div>
                <div className="col-4" style={{ textAlign: "center",  marginTop:"1em", fontSize:"1.05em", paddingLeft: "0px", paddingRight: "0px"  }} > {/* className="col-6" marginLeft: "9.5%",*/}                     
                    <Link to={"/orderApp/"+stateLanguage} onClick={() => { GoOrdertAppF(stateLanguage); }} style={{ color: "purple" }}
                        data-content={stateLanguage && languages[stateLanguage].link4} >                  
                        {/*onClick={() => {  navigate("/orderApp/"+stateLanguage); }} 
                        data-content={stateLanguage && languages[stateLanguage].link4} >*/}
                    {stateLanguage && languages[stateLanguage].link4}  
                    </Link>
                </div>
              </div>
            </div>
          </div>

          {/*row2*/} 
          <div className="row">
            <div className="col-10">
                <div className="row" >
                    <div className="col-4" style={{ textAlign: "left", marginLeft: "9.5%", marginTop:"0.75em", 
                      fontSize:"1em" }}>
                      <Link to={"/privacy-policy/"+stateLanguage}
                        data-content={stateLanguage && languages[stateLanguage].link5} style={{ color: "purple" }} >
                        {stateLanguage && languages[stateLanguage].link5}
                      </Link>
                    </div>                        
                </div>
            </div>
          </div>

          {/*row3*/}
          <div className="row">
            <div className="col-10">
              <div className="row" >
                <div className="col-4" style={{ textAlign: "left", marginLeft: "9.5%", marginTop:"0.75em", 
                      fontSize:"1em" }}>
                  <Link to={"/user-agreement/"+stateLanguage}
                  data-content={stateLanguage && languages[stateLanguage].link6} style={{ color: "purple" }}>
                  {stateLanguage && languages[stateLanguage].link6}
                  </Link>
                </div>
              
              </div>
            </div>
          </div>
      </div>      

      {/*cosial buttom*/}
      <div className="col social-button" >
        <div className=" row icons" >
          <a href="https://www.youtube.com/channel/UCR2iEIw3odMpEz7zYPCOUqg" className="social-link" target="_blank" rel="noreferrer noopener">
            <YouTubeIcon style={{ color: red[700],width: '300%' }}> </YouTubeIcon>
          </a>

          {/* <a href="https://instagram.com/koza_style_official?utm_medium=copy_link" className="social-link" target="_blank">
            <InstagramIcon style={{ color: pink[400],width: '300%'  }}> </InstagramIcon>
          </a> */}
          <div className="madeIn">© 2024, AFOREHAND Studio</div>
        </div>
      </div>
    </div>

  </React.Fragment>
  const mobVers = <React.Fragment>
    <div className="row">
      <div className="col">
        <Link to="/main" className="footer-logo"  onClick={()=>{ window.scrollTo(0,0)}}>
          <img src={logoMob} alt="Logo"></img>
        </Link>
      </div>


      {/*<div className="col social-button">
        <div className=" row icons">
          <a href="https://www.youtube.com/channel/UCR2iEIw3odMpEz7zYPCOUqg" className="social-link" target="_blank" >
            <YouTubeIcon style={{ color: red[700],width:'300%' }}> </YouTubeIcon>
          </a>*/}

          {/* <a href="#" className="social-link" target="_blank">
            <InstagramIcon style={{ color: pink[400] }}> </InstagramIcon>
          </a> */}
       {/* </div>
      </div>*/}

    </div>

    <div className="col" style={{ marginTop: "0.7em" }}>
      <div className="footer-right">
        <div className="row">
          <div className="col" style={{ textAlign: "left", marginLeft: "5%" }}>
            <Link to="/contact" 
              data-content={stateLanguage && languages[stateLanguage].link3} style={{ color: "purple" }}>
              {stateLanguage && languages[stateLanguage].link3}
            </Link>
          </div>
          <div className="col" style={{ textAlign: "left" }}>
            <Link to="/orderApp"
              data-content={stateLanguage && languages[stateLanguage].link4} style={{ color: "purple" }}>
              {stateLanguage && languages[stateLanguage].link4}
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col" style={{ textAlign: "left", marginLeft: "5%" }}>
            <Link to="/privacy-police"
              data-content={stateLanguage && languages[stateLanguage].link5} style={{ color: "purple" }} >
              {stateLanguage && languages[stateLanguage].link5}
            </Link>
          </div>

          <div className="col" style={{ textAlign: "left" }}>
            <Link to="/user-argeement"
              data-content={stateLanguage && languages[stateLanguage].link6} style={{ color: "purple" }}>
              {stateLanguage && languages[stateLanguage].link6}
            </Link>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>

  return (
    <footer className="footer-body" style={{marginBottom:"2em"}}>
      <div className="container "> {/*footer-content*/}
        {window.innerWidth <= 479 ?  mobVers : normalVers}
      </div>
    </footer >
  );
};

export default Footer;
