import React from 'react'
import "./MatevosPrivacy.css";
export default class MatevosPrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateLanguage: props.stateLanguage,
      token: props.token
    };
  }

  /*componentDidUpdate() {
    console.log("state");
    console.log(this.state);
    console.log("props");
    console.log(this.props);
  }*/

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <main className="main">
        <div className="container" style={{ textAlign: "justify"}}>
        <h2 style={{ textAlign: "center", marginTop: "20px" }}><strong>Privacy Policy</strong></h2>
          <h3 style={{ textAlign: "center", marginTop: "10px" }}><strong>Present To Matevos</strong>
          <p style={{ marginTop: "15px" }} > AFOREHAND Studio</p></h3>
          <h4><strong>Introduction</strong></h4>
          <p>AFOREHAND Studio ("Our", "Us" or "We") understands and respects your concerns about privacy. This page is used to inform visitors 
            regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use the <strong>Present To Matevos app</strong>.</p>           
          <p>By visiting and/or using the <strong>Present To Matevos app</strong>, you are acknowledging and accepting this Privacy Policy. </p>
          <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at the <strong>Present To Matevos app.</strong> unless otherwise defined in this Privacy Policy.</p>
          <p>AFOREHAND Studio built the <strong>Present To Matevos app</strong> as a free app. This app is provided by AFOREHAND Studio at no cost and is intended for use as is. 
            Our app operate AS IS, and we do not represent or warrant that our app will be available at all times, or that your use of the Services will be uninterrupted or error-free. 
            We are not responsible for your ability to access the <strong>Present To Matevos app</strong> or for matters beyond our control.</p>

            <h4><strong>Our Policy Towards Children</strong></h4>
          <p>AFOREHAND Studio has developed the <strong>Present To Matevos app</strong> to be an enjoyable experience for users of all ages. Whenever we refer to children in this Privacy Policy, we mean children under the age of 13 
            in the United States (under the age of 16 in Europe), unless otherwise indicated.</p>
          <p>We do not knowingly collect personally identifiable information from any our users. No personal information should be submitted to AFOREHAND Studio by any users. </p>
          <p>In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and 
            you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions.</p>

            <h4><strong>Security</strong></h4>
          <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. 
            But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                
          <h4><strong>Changes to This Privacy Policy</strong></h4>
          <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. 
            We will notify you of any changes by posting the new Privacy Policy on this page.</p>
          <p>We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
          <p>Your continued use of the <strong>Present To Matevos app</strong> after changes have been posted to the Privacy Policy will constitute your acceptance of such changes.</p>          
          <p><strong>This policy is effective as of 2024-10-22</strong></p>

          <h4 style={{ marginTop: "10px" }}><strong>Contacts</strong></h4>
          <p>If you have any questions, concerns, or suggestions regarding this privacy policy, please contact 
            us <i style ={{fontStyle: "italic" }} > support@aforehand.com.ua</i>
          </p>

          
        </div>
      </main>
    );
  }
}