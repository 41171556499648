import React from 'react'
import { Link } from 'react-router-dom';
import * as emailjs from 'emailjs-com';
import Language from "./Language";
import './Contact.css';
import { purple } from '@mui/material/colors';

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stateLanguage: { ...props },
            IsSended: false,
            isEmailOk: false,
            isNameOk: false,
            isMessageOk: false,
            isTermsOfUseOk: false,
            Info: {
                username: "",
                mailfrom: "",
                message: "",
                termsOfUse: false
            },
            sendFormPropertis: {
                service: "service_gf42tma",
                template: "template_oeasndb",
                selector: "#mailform",
                userId: "user_zr3jNsGItXGHKG7MIfu8J"
            }
        };
    }
    //componentDidUpdate() {
        //console.log(this.state)
    //}

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            IsSended: false,
            isEmailOk: false,
            isNameOk: false,
            isMessageOk: false,
            isTermsOfUseOk: false,
            stateLanguage: this.props.stateLanguage
        });
    }

    onFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        var Info = this.state.Info;
        Info[name] = value;
        this.setState({ Info });
    };

    sendMessege = (e) => {
        const { service, template, userId } = this.state.sendFormPropertis;
        const { Info } = this.state;
        var templateParams = {
            head: "Aforehand Studio Контакты",
            username: Info.username,
            mailfrom: Info.mailfrom,
            message1: "Сообщение: " + Info.message
        };
        emailjs
            .send(
                service,
                template,
                templateParams,
                userId
            );
        this.setState({ IsSended: true });
    };

    checkFields = () => {
        var inputEmail = document.getElementById("exampleInputEmail3")?.value;
        var inputName = document.getElementById("exampleInputEmail4")?.value;
        var message = document.getElementById("exampleTextarea1")?.value;
        var termsOfUse1 = document.getElementById("termsOfUse1")?.checked;

        var Info = {
            mailfrom: inputEmail,
            username: inputName,
            message
        };

        var isEmailOk = inputEmail && (inputEmail.indexOf('@') > -1) && (inputEmail.indexOf('.') > -1);
        var isNameOk = inputName && true;
        var isMessageOk = message && true;
        var isTermsOfUseOk = termsOfUse1 && true;

        this.setState({ isEmailOk, isMessageOk, isNameOk, isTermsOfUseOk, Info });
    }

    render() {

        const [languages] = Language;
        const { stateLanguage } = this.props;
        if (this.state.IsSended) {
            const notification1 = stateLanguage === "en" ? "Message sent successfully!" :
                stateLanguage === "es" ? "¡Mensaje enviado con éxito!" :
                    stateLanguage === "ua" ? "Повідомлення успішно надіслано!" :
                        "Nachricht erfolgreich gesendet!";
            const notification2 = stateLanguage === "en" ? " You will be contacted shortly." :
                stateLanguage === "es" ? " Nos comunicaremos con usted en breve." :
                    stateLanguage === "ua" ? " Незабаром з вами зв'яжуться." :
                        " Sie werden in Kürze kontaktiert.";
            const buttontext = stateLanguage === "en" ? "Return to the main page" :
                stateLanguage === "es" ? "Regresar a la pagina principal" :
                    stateLanguage === "ua" ? "Повернення до головної сторінки" :
                        "Zurück zur Hauptseite";
            return (

                <main className="main">
                    <div className="row">
                        <div className="  alert" >
                            <br />
                            <i style={{ color: "black" }}>{notification1}{notification2}</i>
                            <br />
                            <br />
                            <div className="row">
                                <Link to={"/main/"+stateLanguage} className="col-12" style={{ float: 'right' }} >
                                    <button className="btn btn-info buttonStyle" >{buttontext}</button>
                                </Link>
                            </div>
                            <br />
                        </div></div>
                </main>
            );
        }
        const emailplaceholder = stateLanguage === "en" ? "Enter your email" :
            stateLanguage === "es" ? "Introduce tu correo electrónico" :
                stateLanguage === "ua" ? "Введіть свою електронну пошту" :
                    "Geben sie ihre E-Mail Adresse ein";
        const nameplaceholder = stateLanguage === "en" ? "Enter your name" :
            stateLanguage === "es" ? "Introduzca su nombre" :
                stateLanguage === "ua" ? "Введіть своє ім'я" :
                    "Gib deinen Namen ein";
        const warningtext = stateLanguage === "en" ? "Required field" :
            stateLanguage === "es" ? "Campo requerido" :
                stateLanguage === "ua" ? "Обов'язкове поле" :
                    "Pflichtfeld";
                    const textplaceholder = stateLanguage === "en" ? "Any coments" :
            stateLanguage === "es" ? "Introduzca su nombre" :
                stateLanguage === "ua" ? "Коментарі" :
                    "Gib deinen Namen ein";
        var content;
        //const scrWidth = window.screen.availWidth;
        if (!window.mobileCheck()) {
            content =
                <div className="row">
                  <div className="col cont2"></div>
                  <div className="col-1 cont1"></div>
                    <div className="row">
                        <form id="mailform" className="col-10 justify-content-center cont2" >

                            <div className="form-group row description_text" style={{ paddingLeft: '2%', paddingTop: '5%', marginBottom: '0%' }}>
                            {stateLanguage && languages[stateLanguage].directions}

                            </div>

                            <div className={this.state.isEmailOk ? "form-group has-success" : "form-group has-danger"}  style={{ fontSize:"1.25em" }}>
                            {stateLanguage && languages[stateLanguage].emailText}


                            <input
                                type="email"
                                /*id="emailField"*/
                                className={this.state.isEmailOk ? "form-control is-valid" : "form-control is-invalid"}
                                id="exampleInputEmail3" aria-describedby="emailHelp" placeholder={emailplaceholder}
                                name="mailfrom"
                                onChange={() => { this.checkFields() }}
                            />
                            {!this.state.isEmailOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}
                            {stateLanguage && languages[stateLanguage].alertText}

                            {stateLanguage && languages[stateLanguage].nameText}

                            <input
                                type="text"
                                className={this.state.isNameOk ? "form-control is-valid" : "form-control is-invalid"}
                                id="exampleInputEmail4"
                                aria-describedby="emailHelp"
                                placeholder={nameplaceholder}
                                name="username"
                                onChange={() => { this.checkFields() }}
                            />
                            {!this.state.isNameOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}
                            {stateLanguage && languages[stateLanguage].descriptionText}


                            <textarea id="exampleTextarea1" rows="3"
                                name="message"
                                className={this.state.isMessageOk ? "form-control is-valid" : "form-control is-invalid"}
                                onChange={() => { this.checkFields() }}
                                placeholder={textplaceholder}
                            />
                            {/* {!this.state.isMessageOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>} */}
                            {/* <div className="form-check" style={{ paddingTop: '3%' }}>
                                <input className={this.state.isTermsOfUseOk ? "form-check-input" : "form-check-input wrongCheckBox"} type="checkbox" id="termsOfUse1"
                                    name="username"
                                    onChange={() => { this.checkFields() }}
                                />
                                {stateLanguage && languages[stateLanguage].privacyText}

                            </div> */}
                            {!this.state.isMessageOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}
                            <div className="form-check" style={{ paddingTop: '3%', marginLeft: '0.9rem' }}>
                                <input className={this.state.isTermsOfUseOk ? "form-check-input" : "form-check-input wrongCheckBox"} type="checkbox" id="termsOfUse1"
                                    name="username"
                                    onChange={() => { this.checkFields() }}
                                />
                                <div >
                                    <React.Fragment >
                                    {stateLanguage && languages[stateLanguage].privacyText}
                                    </React.Fragment>
                                </div>
                            </div>
                            </div>


                            { this.state.isEmailOk && this.state.isNameOk && this.state.isTermsOfUseOk 
                            ? <button type="submit" className="btn btn-outline-success" style={{ fontSize:"1.25em" }} 
                                onClick={this.sendMessege} >
                                    {stateLanguage && languages[stateLanguage].buttonText}
                                </button>

                                : <button type="submit" style={{ fontSize:"1.25em" }} className="btn btn-outline-success" disabled>
                                    {stateLanguage && languages[stateLanguage].buttonText}
                                </button>}

                        </form>
                    </div>
                    {/*<div className="col cont2" style={{marginBottom:"2em"}}> */}

                  
                    {/*<div className="col-1 cont1"> </div>*/}
                    
                </div>

        }
        else {
            content =
                <div className="row">
                    <form id="mailform" className="col-12 justify-content-center cont1">

                        <div className="form-group row description_text" style={{ marginTop:'50px', paddingLeft: '2%', paddingTop: '5%', marginBottom: '0%' }}>
                            {stateLanguage && languages[stateLanguage].description1}
                            {stateLanguage === "en"
                                ? <p className="col-form-label">Fill&nbsp;out and send&nbsp;the&nbsp;form.<wbr />We&nbsp;will&nbsp;contact&nbsp;you&nbsp;within 24 hours.</p>
                                : stateLanguage === "es"
                                    ? <p className="col-form-label">Complete y envíe el formulario.<br />Nos comunicaremos con usted<br />en un plazo de 24 horas.</p>
                                    : stateLanguage === "ua"
                                        ? <p className="col-form-label">Заповніть та&nbsp;відправити форму.<wbr />Ми&nbsp;зв'яжемося&nbsp;з&nbsp;вами протягом 24 годин.</p>
                                        : stateLanguage === "de"
                                        ?<p className="col-form-label">Füllen Sie das Formular aus<wbr /> und&nbsp;senden&nbsp;Sie&nbsp;es&nbsp;ab.<wbr /> Wir&nbsp;werden&nbsp;Sie innerhalb&nbsp;von&nbsp;24 Stunden kontaktieren.</p>
                                    :stateLanguage === ""
                                    }
                        </div>

                        <div className={this.state.isEmailOk ? "form-group has-success" : "form-group has-danger"}>

                            {stateLanguage === "en"
                                ? <label htmlFor="numberArea" className="form-label mt-4">Number of delivery AR-sets</label>
                                : stateLanguage === "es"
                                    ? <label htmlFor="numberArea" className="form-label mt-4">Número de AR-colocar de entrega</label>
                                    : stateLanguage === "ua"
                                        ? <label htmlFor="numberArea" className="form-label mt-4">Кількість AR-наборів для доставки</label>
                                        : stateLanguage === "de"
                                        ?<label htmlFor="numberArea" className="form-label mt-4">Anzahl der gelieferten AR-einstellen</label>
                                        :stateLanguage === ""
                                    }


                            <input
                                type="email"
                                /*id="emailField"*/
                                className={this.state.isEmailOk ? "form-control is-valid" : "form-control is-invalid"}
                                id="exampleInputEmail3" aria-describedby="emailHelp" placeholder={emailplaceholder}
                                name="mailfrom"
                                onChange={() => { this.checkFields() }}
                            />
                            {!this.state.isEmailOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}

                            {stateLanguage && languages[stateLanguage].alertText}
                            {stateLanguage && languages[stateLanguage].nameText}
                            <input
                                type="text"
                                className={this.state.isNameOk ? "form-control is-valid" : "form-control is-invalid"}
                                id="exampleInputEmail4"
                                aria-describedby="emailHelp"
                                placeholder={nameplaceholder}
                                name="username"
                                onChange={() => { this.checkFields() }}
                            />
                            {!this.state.isNameOk ? <div className="invalid-feedback" style={{ paddingBottom: '1.25em' }}>{warningtext}</div> : <React.Fragment></React.Fragment>}



                            {stateLanguage && languages[stateLanguage].descriptionText}

                            <textarea id="exampleTextarea1" rows="3"
                                name="message"
                                className={"form-control is-valid"}
                            // onChange={() => { this.checkFields() }}
                            />
                            {/* {!this.state.isMessageOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}    */}
                            <div className={"form-check "} style={{ paddingTop: '3%', left: '3.5%' }}>
                                <input className={this.state.isTermsOfUseOk ? "form-check-input" : "form-check-input wrongCheckBox"} type="checkbox" id="termsOfUse1"
                                    name="username"
                                    onChange={() => { this.checkFields() }}
                                />

                                {stateLanguage && languages[stateLanguage].privacyText}

                            </div>
                        </div>


                        {
                            this.state.isEmailOk && this.state.isNameOk && this.state.isTermsOfUseOk ?
                                <button type="submit" className="btn btn-outline-success" onClick={() =>this.sendMessege} >
                                    {stateLanguage === "en"
                                        ? "Send"
                                        : stateLanguage === "es"
                                            ? "Enviar"
                                            : stateLanguage === "ua"
                                                ? "Відправити"
                                                : stateLanguage === "de"
                                                ? "Senden"
                                                :stateLanguage === ""
                                    }
                                </button>
                                : <button type="submit" className="btn btn-outline-success" disabled>
                                    {stateLanguage === "en"
                                        ? "Send"
                                        : stateLanguage === "es"
                                            ? "Enviar"
                                            : stateLanguage === "ua"
                                                ? "Відправити"
                                                : stateLanguage === "de"
                                                ? "Senden"
                                                :stateLanguage === ""
                                    }
                                </button>}


                    </form>

                </div>


        }
        return (
            <main className="main">
                <div className="container">

                    {content}

                </div>
            </main>
        );
    }
}
