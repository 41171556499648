import React from 'react'
import "./GuardiansLegionPrivacy.css";
export default class GuardiansLegionPrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateLanguage: props.stateLanguage,
      token: props.token
    };
  }

  /*componentDidUpdate() {
    console.log("state");
    console.log(this.state);
    console.log("props");
    console.log(this.props);
  }*/

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <main className="main">
        <div className="container" style={{ textAlign: "justify"}}>
         {/*} <h2 style={{ textAlign: "center", marginTop: "20px" }}>*/}
          <h5 style={{ textAlign: "center", marginTop: "10px" }}>
            <strong>Privacy Policy</strong>
          {/*<h3 style={{ textAlign: "center", marginTop: "10px" }}>*/}
          <br />  <strong>Guardians Legion : TD</strong>
          <p style={{ marginTop: "15px" }} > AFOREHAND Studio</p></h5>
          
          <span><strong>Introduction</strong></span>
          <p style={{ textIndent: "40px" }} >AFOREHAND Studio ("Our", "Us" or "We") understands and respects your concerns about privacy. The choices you 
            have about your personal data. This page is used to inform visitors regarding our policies with the collection, use, and disclosure of 
            Personal Information if anyone decided to use the <strong>Guardians Legion : TD</strong> app.</p>           
          <p>By visiting and/or using the <strong>Guardians Legion : TD</strong>, you are acknowledging and accepting this 
          Privacy Policy. </p>
          <p style={{ textIndent: "40px" }} >The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are 
            accessible at the <strong>Guardians Legion : TD</strong> app unless otherwise defined in this Privacy Policy.</p>
          
           <span><strong>About Us</strong></span>           
           <p style={{ textIndent: "40px" }} >AFOREHAND Studio built the <strong>Guardians Legion : TD</strong> app as a free app. This app is provided 
          by AFOREHAND Studio at no cost. Our app operate AS IS, and we do not represent or warrant that our app will be available at all times, or 
            that your use of the <strong>Guardians Legion : TD</strong> app will be uninterrupted or error-free. We are
            not responsible for your ability to access the <strong>Guardians Legion : TD</strong> app or for matters 
            beyond our control.</p>
            <p style={{ textIndent: "40px" }} >If you choose to use my the <strong>Guardians Legion : TD</strong> app, then you agree to the collection 
          and use of information in relation to this policy. Information that we collect is used for providing and 
          improving the <strong>Guardians Legion : TD</strong> app. We will not use or share your information with 
          anyone except as described in this Privacy Policy.</p>  

            <span><strong>Our Policy Towards Children</strong></span>
            <p style={{ textIndent: "40px" }} >AFOREHAND Studio has developed the <strong>Guardians Legion : TD</strong> app to be an enjoyable experience 
          for users of all ages. Whenever we refer to children in this Privacy Policy, we mean children under the age of 
          13 in the United States (under the age of 16 in Europe), unless otherwise indicated.</p>
          <p style={{ textIndent: "40px" }} >We do not knowingly collect personally identifiable information from any our users. No personal information 
            should be submitted to AFOREHAND Studio by any users. In the case we discover that a child under 13 has 
            provided us with personal information, we immediately delete this from our servers. If you are a parent or 
            guardian and you are aware that your child has provided us with personal information, please contact us so 
            that we will be able to do the necessary actions.</p>

            <span><strong>Security</strong></span>
            <p style={{ textIndent: "40px" }} >We value your trust in providing us your personal information, thus we are striving to use commercially 
            acceptable means of protecting it. But remember that no method of transmission over the internet, or method 
            of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

            <span><strong>Links to Other Sites</strong></span>
            <p style={{ textIndent: "40px" }} >The <strong>Guardians Legion : TD</strong> app may contain links to other sites. If you click on a 
          third-party link, you will be directed to that site. Note that these external sites are not operated by us. 
          Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over 
          and assume no responsibility for the content, privacy policies, or practices of any third-party sites or 
          services.</p>    

            <span><strong>Cookies</strong></span>
            <p style={{ textIndent: "40px" }} >Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. 
            These are sent to your browser from the websites that you visit and are stored on your device's internal 
            memory.</p>
          <p>The <strong>Guardians Legion : TD</strong> app does not use these “cookies” explicitly. However, the app 
          may use third-party code and libraries that use “cookies” to collect information and improve their services. 
          You have the option to either accept or refuse these cookies and know when a cookie is being sent to your 
          device. If you choose to refuse our cookies, you may not be able to use some portions of this.</p>  

          <span><strong>Changes to This Privacy Policy</strong></span>
          <p style={{ textIndent: "40px" }} >We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically
          for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These 
          changes are effective immediately after they are posted on this page.</p>
          <p>Your continued use of the <strong>Guardians Legion : TD</strong> app after changes have been posted to 
          the Privacy Policy will constitute your acceptance of such changes.</p> 

          <span><strong>This policy is effective as of 2024-10-22</strong></span>
          <br />
          <span style={{ marginTop: "10px" }}><strong>Contacts</strong></span>
          <p style={{ textIndent: "40px" }} >If you have any questions, concerns, or suggestions regarding this privacy policy, please contact us <i style ={{fontStyle: "italic" }} >
            <strong> support_td@aforehand.com.ua</strong></i>
          </p>

          
        </div>
      </main>
    );
  }
}