import React from 'react'
import "./MatevosPrivacy.css";
export default class MatevosATermsConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateLanguage: props.stateLanguage,
      token: props.token
    };
  }

  /*componentDidUpdate() {
    console.log("state");
    console.log(this.state);
    console.log("props");
    console.log(this.props);
  }*/

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <main className="main">
        <div className="container" style={{ textAlign: "justify"}}>

        <h2 style={{ textAlign: "center", marginTop: "20px" }}><strong>Terms & Conditions</strong></h2>
          <h3 style={{ textAlign: "center", marginTop: "10px" }}><strong>Present To Matevos</strong>
          <p style={{ marginTop: "15px" }} > AFOREHAND Studio</p></h3>
          <h4><strong>Acceptance of the Terms</strong></h4>
          <p>The Present To Matevos app is provided by AFOREHAND Studio ("Us" or "We"). By downloading or using the <strong>Present To Matevos app</strong>, these terms will automatically apply 
            to you – you should make sure therefore that you read them carefully before using the app. </p>
          <p>If you do not agree with any part of the Terms, then you should immediately terminate the use of the app.</p>

          <h4><strong>Privacy</strong></h4>
          <p>See Privacy Policy, related to the processing of your personal data within the <strong>Present To Matevos app</strong>. </p>

          <h4><strong>Your use of the app</strong></h4>
          <p>You are solely responsible for your use of the <strong>Present To Matevos app</strong> and any consequences resulting from the use of the Services.</p>
          <p>It’s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, 
            which is the process of removing software restrictions and limitations imposed by the official operating system of your device. 
            It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the <strong>Present To Matevos app</strong> won’t work properly or at all.</p>

          <h4><strong>Permissions</strong></h4>
          <p>You hereby grant AFOREHAND Studio the permission to update through upgrades the app that you use.</p>
          <p>The <strong>Present To Matevos app</strong> is currently available on Android – the requirements for the system (and for any additional systems we decide to extend 
            the availability of the app to) may change, and you’ll need to download the updates if you want to keep using the app. AFOREHAND Studio does not 
            promise that it will always update the <strong>Present To Matevos app</strong>, so that it is relevant to you and/or works with the Android version that you have 
            installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, 
            and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, 
            (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.</p>

          <h4><strong>Your use of the app</strong></h4>
          <p>You are solely responsible for your use of the <strong>Present To Matevos app</strong> and any consequences resulting from the use of the Services.</p>
          <p>It’s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, 
            which is the process of removing software restrictions and limitations imposed by the official operating system of your device. 
            It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the 
            <strong>Present To Matevos app</strong> won’t work properly or at all.</p>

          <h4><strong>Permissions</strong></h4>
          <p>You hereby grant AFOREHAND Studio the permission to update through upgrades the app that you use.</p>
          <p>The <strong>Present To Matevos app</strong> is currently available on Android – the requirements for the system (and for any additional systems we decide to extend 
            the availability of the app to) may change, and you’ll need to download the updates if you want to keep using the app. AFOREHAND Studio does not 
            promise that it will always update the <strong>Present To Matevos app</strong>, so that it is relevant to you and/or works with the Android version that you have 
            installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, 
            and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, 
            (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.</p>

          <h4><strong>Disclaimers</strong></h4>
          <p>AFOREHAND Studio cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged – if it runs out of 
            battery and you can’t turn it on to avail the <strong>Present To Matevos app</strong>, AFOREHAND Studio cannot accept responsibility.</p>

          <h4><strong>Your obligations</strong></h4>
          <p>You’re not allowed to copy or modify the app, any part of the app, or our trademarks in any way. You’re not allowed to attempt to extract 
            the source code of the app, and you also shouldn’t try to translate the app into other languages or make derivative versions. </p>
          <p>The app itself, and all the trademarks, copyright and other intellectual property rights related to it, still belong to AFOREHAND Studio.</p>    
          <p>AFOREHAND Studio is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make 
            changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without 
            making it very clear to you exactly what you’re paying for.</p>

          <h4><strong>Amendments</strong></h4>
          <p>At some point, we may wish to update the app. Thus, you are advised to review this page periodically for any changes. We will notify you 
            of any changes by posting the new Terms and Conditions on this page.</p>
          <p>These Terms apply to any updates, enhancements, new features of the <strong>Present To Matevos app</strong>.</p>    
          <p>Your continued use of the <strong>Present To Matevos app</strong> after changes have been posted to the Terms and Conditions will 
          constitute your acceptance of such changes.</p>
          <p><strong>This policy is effective as of 2024-10-22</strong></p>

          <h4 style={{ marginTop: "10px" }}><strong>Contacts</strong></h4>
          <p>If you have any questions, concerns, or suggestions regarding this privacy policy, please contact us <i style ={{fontStyle: "italic" }} > support@aforehand.com.ua</i>
          </p>
        </div>
      </main>
    );
  }
}