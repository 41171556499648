
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
//import ReactPlayer from "react-player/youtube"
import arkozalogo from "../../images/icon_v10.png";
import playserviceLogo from "../../images/GooglePlay.png";
//#import gplayLogo from "../../images/gplay.png";
//#import playserviceLogoMob from "../../images/gplay.png";
import ar_koza_party_text from "../../images/ar_koza_party_text.webp";
import arkoza_prev1 from "../../images/for_web_hb_775.png";
import arkoza_prev2 from "../../images/for_web_sakura_v4.jpg";
import towers_icon from "../../images/logo_app_v8_512.webp"
import towers_text from "../../images/name4_for_site.png"
//name3_towers.webp"
import arGreetingText from "../../images/logo.png";
import arGreetingLogo from "../../images/logoAR.png";
import googleplay from "../../images/GooglePlay.png";
//style
import './MainPage.css';
import { SaveToLocalStorage } from "../../Components/SaveToLocalStorage/SaveToLocalStorage"
import mainGif from '../../images/slide3_gif.gif';
//import locMy from './LocMy.jsx';

//const MainPage = ({ stateLanguage }) => {

class MainPage extends React.Component {        

  constructor(props) {
    super(props); 
    
    this.state = { 
      stateLanguage: { ...props }, 
      //locMy: {...props},
      timer:null
    };
    this.myRefBlock = React.createRef();
  }  
   
    // Scroll to ref function
    scrollToMyRef = () => {
      //window.scrollTo({ top:this.myRefBlock.offsetTop,  behavior: "smooth" });// optional , block:"start"
      this.myRefBlock.current. scrollIntoView({ behavior: "smooth"  });   
    
  };

  componentDidMount() 
   {      
    this.setState({
      stateLanguage: this.props.stateLanguage,
      timer: setInterval(
        ()=>{
          let el = document.getElementById("loppgif");
          if(el){
            el.setAttribute('src',mainGif)
          }
        } ,12200 )  //12200        
    } );
    
    //const locationD = useLocation();
    const storedStyle =localStorage.getItem("ONCLICKSTYLE");       
      //console.log ("ONCLICKSTYLE ", storedStyle, ".");*/
      //console.log("must jump" ) ;
      let localRef = null;
    if (this.myRefBlock.current) localRef = this.myRefBlock.current; //imgRef.current;
    //console.log("localRef: ", localRef ) ;
    if ( storedStyle =="scrollPad")  { 
      this.scrollToMyRef();
    }
      //this.myRefBlock.current. scrollIntoView({ behavior: "auto", block:"start"  });   //
      //window.getElementById("arProjects").style.display = "none";
      //scrollTo({top: 200, behavior: 'smooth',}) document
      //window.scrollTo(0,0);//storedStyle 
       //this.scrollView.scrollToElement(document.querySelector('#block2'));      
      //} 
      /*else 
      { SaveToLocalStorage ("ONCLICKSTYLE", " ");  }
       */
   
  }

 /*componentDidUpdate() {
    //console.log(this.state);
    this.setState({
      stateLanguage: this.props.stateLanguage,
      timer: setInterval(
        ()=>{
          let el = document.getElementById("loppgif");
          if(el){
            el.setAttribute('src',mainGif)
          }
        } ,12200 )  //12200        
    } );
    
    const storedStyle =localStorage.getItem("ONCLICKSTYLE");   
     // console.log ("ONCLICKSTYLE ", storedStyle, ".");
    if ( storedStyle =="scrollPad")  
      {  this.myRefBlock2.current.scrollTo({top: 0, behavior: 'smooth',})
        //scrollIntoView({ behavior: 'smooth', block:"start"  })  
     }  
      else {window.scrollTo(0,0);}; 
       //this.scrollView.scrollToElement(document.querySelector('#block2'));      
     
  }*/


  render() {
    var stateLanguage   = this.props.stateLanguage;    
      //console.log ("stateLanguage Main", stateLanguage);     
      var locationM= window.location.href;
      //console.log ("window.location.href", locationM);
      const storedStyle =localStorage.getItem("ONCLICKSTYLE");       
      const clasScroll = "row "+ storedStyle;  
        /* console.log("clasScrol", clasScroll);       */
        // console.log ("ONCLICKSTYLE render Main", localStorage.getItem("ONCLICKSTYLE"), ".");  
       // if ( storedStyle =="scrollPad")    this.scrollToMyRef();
      //if ( storedStyle =="scrollPad") { //this.myRefBlock.current. scrollIntoView({ behavior: "auto", block:"start"  });   
      //    window.scrollToElement("arProjects").style.display="none";};
      var needPadding = (window.screen.availWidth < 1059 && window.screen.availWidth > 479);
    
    const mainClassName = ((needPadding) ? " top-margin" : "" );
    //const marginForblocks= Math.round(document.getElementsByTagName('nav')[0]?.getBoundingClientRect().height??0,-2);
    //alert(marginForblocks);  

   //////////////////////////   
  const checkPathNameForLinkBySwitchingLanguages = (l) => {
    var arr = [ 'en', 'ua', 'es', 'de']; //'EN', 'UA', 'ES', 'DE',
    for (var i = 0; i < arr.length; i++) {
        if (l === arr[i]) return true;
    }
    return false;
  }
    
    const onSetStyleA=(NewLG) => {
      var s=locationM.split('/').length;   
      var oldLang =locationM.split('/')[s-1];  
      if ( checkPathNameForLinkBySwitchingLanguages(oldLang) ) { 
      //var pathnameMy =locationM.replace( oldLang,"") ;                
      var pathnameNew = "/ar-koza-party/"+NewLG; 
      }
        else    var pathnameNew = "/ar-koza-party/en"; 

      return pathnameNew ;        
      }
/////////////////////////////////////*/
 


var mobileContent = <React.Fragment>
  <div className='container' >
    <div className="col-11" style={{margin: '0px auto 0'}}>
      <h3 className="mini-header" id="block3">
        <React.Fragment>B2B</React.Fragment>
      </h3>
      <div className="col-12">
        <div className="h-100" style={{margin: '0 auto'}}>
          <div className="row pictureStyle">
            <div className="col-4">
              <img className="koza-logo1" src={arkozalogo} alt="AR-KOZA-PARTY LOGO"/>
            </div>
            <div className="col-4">
              <img className="koza-logo2" src={ar_koza_party_text} alt="AR-KOZA-PARTY TEXT"/>
            </div>
            <div className="col-4">
              <a href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" target="_blank" rel="noreferrer noopener">
                <img className="koza-logo3" src={playserviceLogo} alt="GOOGLE PLAY MARKET LOGO"/>
                {/*<img className="koza-logo4" src={playserviceLogoMob} alt="GOOGLE PLAY MARKET LOGO" />*/}
              </a>
            </div>
          </div>
          <div className='row col-12 ' style={{margin: '0 auto'}}>
            <div className='col-2'></div>
            <div className='col-4 '>
              <img src={arkoza_prev1} alt="AR-KOZA-PARTY LOGO"/>
            </div>
            <div className='col-4'>
              <img src={arkoza_prev2} alt="AR-KOZA-PARTY LOGO"/>
            </div>
            {/* <img className="col-4" src={arkoza_prev1} alt="AR-KOZA-PARTY LOGO" />
                <img className="col-4" src={arkoza_prev2} alt="AR-KOZA-PARTY LOGO" /> */}
          </div>


          <div className="description-text" style={{textAlign: 'center', marginBottom: '0.1em', fontSize: "1.25em"}}>
            {stateLanguage === "en"
                ? <React.Fragment>Augmented reality scenes on <a style={{color: "purple"}}
                                                                 href="https://kharkov.prom.ua/c667862-koza-style.html">KOZA‑STYLE</a> cardboard
                  disposable tableware</React.Fragment>
                : stateLanguage === "es"
                    ? <React.Fragment>Escenas de juegos de realidad aumentada sobre vajilla desechable de cartón de <a
                        style={{color: "purple"}} href="https://kharkov.prom.ua/c667862-koza-style.html">KOZA‑STYLE</a></React.Fragment>
                    : stateLanguage === "ua"
                        ?
                        <React.Fragment>Ігрові сцени в доповненій реальності на картонному одноразовому посуді фірми <a
                            style={{color: "purple"}}
                            href="https://kharkov.prom.ua/c667862-koza-style.html">KOZA‑STYLE</a></React.Fragment>
                        : <React.Fragment>Augmented Reality Spielszenen auf Einweggeschirr aus Pappe von <a
                            style={{color: "purple"}}
                            href="https://kharkov.prom.ua/c667862-koza-style.html">KOZA‑STYLE</a></React.Fragment>}
          </div>
          <div className='col-12' style={{textAlign: 'center'}}>
            <Link to={"/ar-koza-party"} style={{marginTop: "0", marginBottom: "0.5em"}}>
              {/* <Link to={"/ar-koza-party"+((stateLanguage!=undefined) ? "/"+stateLanguage.toLowerCase(): "")} style={{ marginTop: "0", marginBottom: "0.3em" }}>  */}
              {stateLanguage === "en"
                  ? <button className="btn btn-outline-primary buttonStyle">More details</button>
                  : stateLanguage === "es"
                      ? <button className="btn btn-outline-primary buttonStyle">Más detalles</button>
                      : stateLanguage === "ua"
                          ? <button className="btn btn-outline-primary buttonStyle">Детальніше</button>
                          : <button className="btn btn-outline-primary buttonStyle">Mehr Details</button>}
            </Link>
          </div>
        </div>
      </div>


      <h3 className="mini-header" id="block1">
        {stateLanguage === "en"
            ? <React.Fragment>Game projects</React.Fragment>
            : stateLanguage === "es"
                ? <React.Fragment>Proyectos de juegos </React.Fragment>
                : stateLanguage === "ua"
                    ? <React.Fragment>Ігрові проекти</React.Fragment>
                    : <React.Fragment>Spielprojekte </React.Fragment>}
      </h3>
      <div className="container">
        <div style={{textAlign: "center", marginTop: "0.3em", marginBottom: "0.3em"}} className="description-text">
          <React.Fragment>Coming Soon...</React.Fragment>
        </div>
      </div>
      <div>
        <div id="block2">
          <h3 className="mini-header">
            {stateLanguage === "en"
                ? <React.Fragment>Our services</React.Fragment>
                : stateLanguage === "es"
                    ? <React.Fragment>Nuestros servicios</React.Fragment>
                    : stateLanguage === "ua"
                        ? <React.Fragment>Наші сервіси</React.Fragment>
                        : <React.Fragment>Unsere Dienstleistungen</React.Fragment>}
          </h3>
        </div>
        <div className="col-12">
          <div className="h-100" style={{margin: '0 auto'}}>
            <div className="row pictureStyle">
              <div className="col-4">
                <img className="koza-logo1" src={arGreetingLogo} alt="AR-KOZA-PARTY LOGO"/>
              </div>
              <div className="col-4">
                <img className="koza-logo2" src={arGreetingText} alt="AR-KOZA-PARTY TEXT"/>
              </div>
              <div className="col-4">
                {/*<a>*/}
                  <img className="koza-logo3" src={playserviceLogo} alt="GOOGLE PLAY MARKET LOGO"/>
                  {/*<img className="koza-logo4" src={playserviceLogoMob} alt="GOOGLE PLAY MARKET LOGO" />*/}
                {/*</a>*/}
              </div>
            </div>

            <div className='row col-12 ' style={{margin: '0 auto'}}>
              <div className='col-2'></div>
              <img id="loppgif" src={mainGif} style={{borderRadius: "4em", backgroundRepeat: "repeat"}}/>
              {/* <img className="col-4" src={arkoza_prev1} alt="AR-KOZA-PARTY LOGO" />
                <img className="col-4" src={arkoza_prev2} alt="AR-KOZA-PARTY LOGO" /> */}
            </div>


            <div className="description-text" style={{textAlign: 'center', marginBottom: '0.1em', fontSize: "1.25em"}}>
              {stateLanguage === "en"
                  ? <React.Fragment>The ARgreetings is the platform for communicate with friends through AR-scenes and
                    models.<br/> Take the plunge into AR with ARgreetings!</React.Fragment>
                  : stateLanguage === "es"
                      ?
                      <React.Fragment>ARgreetings es la plataforma para comunicarse con amigos a través de AR-escenas y
                        modelos. <br/>¡Sumérjase en AR con ARgreetings! ¡Prueba o conviértete en
                        coautor!</React.Fragment>
                      : stateLanguage === "ua"
                          ? <React.Fragment>ARgreetings — платформа для спілкування з друзями через AR-сцени та
                            моделі.<br/> Пориньте в AR з ARgreetings!</React.Fragment>
                          : <React.Fragment>ARgreetings ist eine Plattform für die Kommunikation mit Freunden über
                            AR-Szenen und -Modelle. <br/>Tauchen Sie mit ARgreetings in AR ein!</React.Fragment>}
            </div>

            <div className='col-12' style={{textAlign: 'center'}}>
              <a href={"https://argreetings.aforehand.com.ua"} targer="_blank"
                 style={{marginTop: "0", marginBottom: "0.5em"}}>
                {/* <Link to={"/ar-koza-party"+((stateLanguage!=undefined) ? "/"+stateLanguage.toLowerCase(): "")} style={{ marginTop: "0", marginBottom: "0.3em" }}>  */}
                {stateLanguage === "en"
                    ? <button className="btn btn-outline-primary buttonStyle">More details</button>
                    : stateLanguage === "es"
                        ? <button className="btn btn-outline-primary buttonStyle">Más detalles</button>
                        : stateLanguage === "ua"
                            ? <button className="btn btn-outline-primary buttonStyle">Детальніше</button>
                            : <button className="btn btn-outline-primary buttonStyle">Mehr Details</button>}
              </a>
            </div>

          </div>
        </div>


      </div>
    </div>
  </div>
</React.Fragment>;
    var isMobile = window.mobileCheck();
      // console.log("Mobile:" + isMobile);
    



 return((!isMobile) ?
      <main className={"main" + mainClassName} > {/*style={{ paddingBottom: "0%" }}*/}

    {/* block1 Game projects*/      }
      <div className="container">  {/* 'container'>   */}
        <div className="col-12" > {/*style={{ margin: '0 auto' }}*/}

          <h5 className="mini-header block1" > {/*" id=" {"main" + mainClassName}*/}
            {stateLanguage === "en"
              ? <React.Fragment>GAME PROJECTS</React.Fragment>
              : stateLanguage === "es"
                ? <React.Fragment>PROYECTOS de JUEGOS</React.Fragment>
                : stateLanguage === "ua"
                  ? <React.Fragment>ІГРИ</React.Fragment>
                  : <React.Fragment>SPIEL PROJEKTE</React.Fragment>}
          </h5>

          <div className="row" >
            <div > {/*} className="h-100" style={{ margin: '0 auto' }}>*/}
              
              <div className="row pictureStyle" >
                  <div className="col-4" style={{ alignContent: "center"  }}> {/*style={{ textAlign: "left", alignContent: "center"  }}*/}
                      <img className="towers-logo1" src={towers_icon} alt="Guardians Legion :TD LOGO" />
                  </div>

                  <div className="col-4" style={{ alignContent: "center"  }}>
                    <a href="https://play.google.com/store/apps/details?id=com.Aforehandstudio.Towers" > 
                      <img className="towers-logo2" src={towers_text} alt="Guardians Legion :TD TEXT" />
                    </a>  
                  </div>

                  <div className="col-4" style={{ textAlign:"right", alignContent: "center"  }}>
                    <a href="https://play.google.com/store/apps/details?id=com.Aforehandstudio.Towers" > 
                        <img width="200em"  src={googleplay} alt="Links to download" />
                    </a>  
                  </div>
              </div>

            </div>
          </div>

          <div  className="row videoWrappermain">  {/* col-12 ' style={{ margin: '0 auto' }} videoWrappermain*/}
              {/*<ReactPlayer  className="video-cssmain" url='https://youtu.be/J_gS-i8uEAA' loop='true' playing='false' muted='true' /> //560 315 width="100%" height="100%"  */} 
              <iframe className="video-cssmain" src="https://www.youtube.com/embed/J_gS-i8uEAA?si=bg7mbrtTGsboDfB-&autoplay=1&mute=0&loop=1" title="YouTube video player" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" 
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>                                                                                              
              </iframe> 
          </div>

          <div className="description-text"  style={{ textAlign: 'center', fontSize: "1.25em", marginBottom:"1em" }} >
                {stateLanguage === "en"
                  ? <React.Fragment>Equip the Legion of Guardians to brave in front foes and defense the Castle!</React.Fragment>
                  : stateLanguage === "es"
                    ? <React.Fragment>¡Equipa a la Legión para enfrentar a los enemigos y defender el Castillo!</React.Fragment>
                    : stateLanguage === "ua"
                      ? <React.Fragment>Споряджуй Легіон Стражів, щоб сміливо битися з ворогами та захищати Замок!</React.Fragment>
                      : stateLanguage === "de"
                      ?<React.Fragment>Rüste die Legion aus, um dich den Feinden zu stellen und die Burg zu verteidigen!</React.Fragment>
                      :stateLanguage === ""
                      }
          </div>
        </div>
      </div>

    {/* block2 AR for Business*/}
      <div className="container" ref={this.myRefBlock} > {/*'container'>      style={{marginTop: marginScrol + 'em'}}  id ="AR" */}
        <div className={clasScroll}  > {/*"row" */}
        <div className="col-12"  id="arProjects" > {/* style={{ margin: '0 auto' }}*/}
          <h5 className="mini-header2"  > {/*  */}
                  {/*" id="    <React.Fragment>Augmented Reality (AR) projects</React.Fragment>*/}
                {stateLanguage === "en"
                  ? <React.Fragment>Augmented Reality (AR) projects</React.Fragment>
                  : stateLanguage === "es"
                   ? <React.Fragment>Proyectos de Realidad Aumentada (RA) </React.Fragment>
                    : stateLanguage === "ua"
                     ? <React.Fragment>AR-проекти (доповнена дійсність)</React.Fragment>
                      : <React.Fragment>Augmented Reality (AR) Projekte </React.Fragment>}
          </h5>

          <div className="col-12"  >            
          <div className="h-100" style={{ margin: '0 auto' }}>
            
            {/*AR-KOZA-PARTY icon+LOGOs */}
            <div className="row pictureStyle"  >
              <div className="col-4">
                  <img className="koza-logo1" src={arkozalogo} alt="AR-KOZA-PARTY LOGO" />
              </div>
              <div className="col-4">
                  <img className="koza-logo2" src={ar_koza_party_text} alt="AR-KOZA-PARTY TEXT" />
              </div>
              <div className="col-4">
                  <p style={{ fontSize: "1.25em", marginTop: "0.75em", marginBottom: "0.75em", textAlign:"right"}}> 
                    <a href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" > 
                    <img width="200em"  src={googleplay} alt="Links to download" /></a>  
                  </p>
                  {/*<a href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" target="_blank" rel="noreferrer noopener">
                    <img className="koza-logo3" src={playserviceLogo} alt="GOOGLE PLAY MARKET LOGO" /> {/*<img className="koza-logo4" src={playserviceLogoMob} alt="GOOGLE PLAY MARKET LOGO" />*/}
                 {/*} </a> */}
              </div>
            </div>

            {/*AR-KOZA-PARTY screens+text */}
            <div className='row col-12 ' style={{ margin: '0 auto' }}>
                <div className='col-2'></div>
                <div className='col-4 '>
                  <img src={arkoza_prev1} alt="AR-KOZA-PARTY screen1" />
                </div>
                <div className='col-4'>
                  <img src={arkoza_prev2} alt="AR-KOZA-PARTY screen2" />
                </div>
                {/* <img className="col-4" src={arkoza_prev1} alt="AR-KOZA-PARTY LOGO" />
                <img className="col-4" src={arkoza_prev2} alt="AR-KOZA-PARTY LOGO" /> */}
            </div>
            <div className="description-text" style={{ textAlign: 'center', marginBottom: '0.1em', fontSize: "1.25em" }}>
                {stateLanguage === "en"
                  ? <React.Fragment>Augmented reality scenes on <a style={{ color: "purple" }} href="https://koza-style.com.ua/work.php">KOZA‑STYLE</a> cardboard disposable tableware.</React.Fragment>
                  : stateLanguage === "es"
                    ? <React.Fragment>Escenas de juegos de realidad aumentada sobre vajilla desechable de cartón de <a style={{ color: "purple" }} href="https://koza-style.com.ua/work.php">KOZA‑STYLE</a>.</React.Fragment>
                    : stateLanguage === "ua"
                      ? <React.Fragment>Ігрові сцени в доповненій реальності на картонному одноразовому посуді фірми <a style={{ color: "purple" }} href="https://koza-style.com.ua/work.php">KOZA‑STYLE</a>.</React.Fragment>
                      : stateLanguage === "de"
                      ?<React.Fragment>Augmented Reality Spielszenen auf Einweggeschirr aus Pappe von <a style={{ color: "purple" }} href="https://koza-style.com.ua/work.php">KOZA‑STYLE</a>.</React.Fragment>
                      :stateLanguage === ""
                }
             </div>

            {/* button*/}
            <div className='col-12'   style={{ textAlign: 'center' }} 
             > {/*onClick={() => { onSetStyleA(stateLanguage)  }}*/}
              
                <Link to={onSetStyleA(stateLanguage) }  style={{ marginTop: "0.75em", marginBottom: "1.5em" }} //"/ar-koza-party/"+(stateLanguage) 
                 >
                {/*//</div>onClick={() => { onSetStyleA(stateLanguage)}}>*/}                                  
                  
                  {/*SaveToLocalStorage("ONCLICKSTYLE", " "); }}> //ar-koza-party*/}

                {/*<Link to={"/ar-koza-party"+((stateLanguage!=undefined) ? "/"+stateLanguage: "")} */}
                  {/* <Link to={"/ar-koza-party"+((stateLanguage!=undefined) ? "/"+stateLanguage.toLowerCase(): "")} style={{ marginTop: "0", marginBottom: "0.3em" }}>  */}
                  {stateLanguage === "en"
                    ? <button className="btn btn-outline-primary buttonStyle"  >More details</button>
                    : stateLanguage === "es"
                      ? <button className="btn btn-outline-primary buttonStyle" >Más detalles</button>
                      : stateLanguage === "ua"
                        ? <button className="btn btn-outline-primary buttonStyle" >Детальніше</button>
                        : stateLanguage === "de"
                        ?<button className="btn btn-outline-primary buttonStyle" >Mehr Details</button>
                        :stateLanguage === ""
                 }
               </Link>
            </div>
          
          </div>
          </div>

        </div>
        </div>
      </div>

    {/* block2.2 = was block3 Our services*/}
      <div className='container' >
        <div className="col-12"  style={{ margin: '0 auto' }}> {/* id="block3" >*/}
          <h5 className="mini-header" style={{ backgroundColor: "white",  borderBottom: '1px solid orange'}} >
             {/*} {stateLanguage === "en"
                ? <React.Fragment>Our services</React.Fragment>
                : stateLanguage === "es"
                  ? <React.Fragment>Nuestros servicios</React.Fragment>
                  : stateLanguage === "ua"
                    ? <React.Fragment>Наші сервіси</React.Fragment>
                    : <React.Fragment>Unsere Dienstleistungen</React.Fragment>}*/}
          </h5>

          <div className="col-12" >
            <div className="h-100" style={{ margin: '0 auto' }}>
              <div className="row pictureStyle" >
                <div className="col-4">
                  <img className="koza-logo1" src={arGreetingLogo} alt="AR-KOZA-PARTY LOGO" />
                </div>
                <div className="col-4">
                  <img className="koza-logo2" src={arGreetingText} alt="AR-KOZA-PARTY TEXT" />
                </div>
                <div className="col-4">
                  <p style={{ fontSize: "1.25em", marginTop: "0.75em", marginBottom: "0.75em", textAlign:"right"}}> 
                    {/*<a href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" > */}
                    <img width="200em"  src={googleplay} alt="Links to download" /> {/*</a>  */}
                  </p>
                  {/*<img className="koza-logo3" src={playserviceLogo} alt="GOOGLE PLAY MARKET LOGO" />
                    {/*<img className="koza-logo4" src={playserviceLogoMob} alt="GOOGLE PLAY MARKET LOGO" />*/}
                  {/*</a>*/}
                </div>
              </div>
            <div className='row col-12 ' style={{ margin: '0 auto' }}>
                <div className='col-2'></div>
                <img id="loppgif" src={mainGif} style={{borderRadius:"4em", backgroundRepeat:"repeat"}} alt="ARgreeting"/>
                {/* <img className="col-4" src={arkoza_prev1} alt="AR-KOZA-PARTY LOGO" />
                <img className="col-4" src={arkoza_prev2} alt="AR-KOZA-PARTY LOGO" /> */}
          </div>
              
              <div className="description-text" style={{ textAlign: 'center', marginBottom: '0.1em', fontSize: "1.25em" }}>
                {stateLanguage === "en"
                  ? <React.Fragment>The ARgreetings is the platform for communicate with friends through AR-scenes and  models.<br/> Take the plunge into AR with ARgreetings!</React.Fragment>
                  : stateLanguage === "es"
                    ? <React.Fragment>ARgreetings es la plataforma para comunicarse con amigos a través de AR-escenas y modelos. <br/>¡Sumérjase en AR con ARgreetings! ¡Prueba o conviértete en coautor!</React.Fragment>
                    : stateLanguage === "ua"
                      ? <React.Fragment>ARgreetings — платформа для спілкування з друзями через AR-сцени та моделі.<br/> Пориньте в AR з ARgreetings!</React.Fragment>
                      : <React.Fragment>ARgreetings ist eine Plattform für die Kommunikation mit Freunden über AR-Szenen und -Modelle. <br/>Tauchen Sie mit ARgreetings in AR ein!</React.Fragment>}
              </div>
              
              <div className='col-12' style={{ textAlign: 'center', marginTop: "0.75em", marginBottom: "1.55em" }}>
                <a href={"https://argreetings.aforehand.com.ua"} targer="_blank"style={{ marginTop: "0", marginBottom: "0.5em" }}>
                  {/* <Link to={"/ar-koza-party"+((stateLanguage!=undefined) ? "/"+stateLanguage.toLowerCase(): "")} style={{ marginTop: "0", marginBottom: "0.3em" }}>  */}
                  {stateLanguage === "en"
                    ? <button className="btn btn-outline-primary buttonStyle"  >More details</button>
                    : stateLanguage === "es"
                      ? <button className="btn btn-outline-primary buttonStyle" >Más detalles</button>
                      : stateLanguage === "ua"
                        ? <button className="btn btn-outline-primary buttonStyle" >Детальніше</button>
                        : stateLanguage === "de"
                        ?<button className="btn btn-outline-primary buttonStyle" >Mehr Details</button>
                      :stateLanguage === ""
                      }
                </a>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    {/*</React.Fragment>;*/}

      </main >
      
      : <main className={"main" + mainClassName} style={{ paddingBottom: "0%", paddingTop:'99.77px' }}>
        {mobileContent}
      </main>
    );
  }
}

export default MainPage;