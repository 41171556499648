import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux"
import {BrowserRouter} from "react-router-dom";

import App from './App';
import store from './Redux/store/store'

import "./css/base.css";
import "./css/variable.css";

/*ReactDOM.render(<Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
        </Provider>,
    document.getElementById('root')
);*/
//for React 18
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(    
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
         </Provider>
    </React.StrictMode>            
            );
//<App />);