import React from 'react'
import "./GuardiansLegionTerms.css";
export default class GuardiansLegionATerms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateLanguage: props.stateLanguage,
      token: props.token
    };
  }

 /* componentDidUpdate() {
    console.log("state");
    console.log(this.state);
    console.log("props");
    console.log(this.props);
  }*/

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <main className="main">
        <div className="container" style={{ textAlign: "justify"}}>
        {/*<h2 style={{ textAlign: "center", marginTop: "20px" }}><strong>Terms & Conditions</strong></h2>*/}
        <h5 style={{ textAlign: "center", marginTop: "10px" }}> 
        <strong>Terms & Conditions</strong>
        {/*<h3 style={{ textAlign: "center", marginTop: "10px" }}>*/}
        <br /> <strong >Guardians Legion : TD</strong>
        <p style={{ marginTop: "15px" }} > AFOREHAND Studio</p> </h5>

          {/*<h4><strong>Acceptance of the Terms and Conditions</strong></h4>*/}
          <span><strong>Acceptance of the Terms and Conditions</strong></span>
          <p style={{ textIndent: "40px" }} >The <strong>Guardians Legion : TD</strong> app is provided by AFOREHAND Studio ("Our", 
          "Us" or "We"). By downloading or using the <strong>Guardians Legion : TD</strong> app, these terms will automatically apply 
          to you – you should make sure therefore that you read them carefully before using the app. </p>
          <p style={{ textIndent: "40px" }}>If you do not agree with any part of the Terms and Conditions, then you should immediately terminate the use of the app.</p>

          <span><strong>Privacy</strong></span>
          <p style={{ textIndent: "40px" }} >See Privacy Policy, related to the processing of your personal data within 
            the <strong>Guardians Legion : TD</strong> app. </p>

          <span><strong>Your use of the app</strong></span>
          <p style={{ textIndent: "40px" }} >You are solely responsible for your use of the <strong>Guardians Legion : TD</strong> app 
          and any consequences resulting from the use of this app. It’s your responsibility to keep your phone and access 
          to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of 
          removing software restrictions and limitations imposed by the official operating system of your device. It could 
          make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and 
          it could mean that the <strong>Guardians Legion : TD</strong> app won’t work properly or at all.</p>

          <span><strong>Permissions</strong></span>
          <p style={{ textIndent: "40px" }} >You hereby grant AFOREHAND Studio the permission to update through upgrades 
            the app that you use.</p>
          <p style={{ textIndent: "40px" }} >The <strong>Guardians Legion : TD</strong> app is currently available on Android –
          the requirements for the system (and for any additional systems we decide to extend 
            the availability of the app to) may change, and you’ll need to download the updates if you want to keep using 
            the app. AFOREHAND Studio does not promise that it will always update the <strong>Guardians Legion : TD</strong> app, 
            so that it is relevant to you and/or works with the Android version that you have installed on your device. However, you 
            promise to always accept updates to the application when offered to you, we may also wish to stop providing the app, 
            and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, 
            upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the 
            app, and (if needed) delete it from your device.</p>          

            <span><strong>Disclaimers</strong></span>
          <p style={{ textIndent: "40px" }} >AFOREHAND Studio cannot always take responsibility for the way you use the 
            app i.e. you need to make sure that your device stays charged – if it runs out of battery and you can’t turn 
            it on to avail the <strong>Guardians Legion : TD</strong> app, AFOREHAND Studio cannot accept responsibility.</p>
          <p style={{ textIndent: "40px" }} > With respect to AFOREHAND Studio responsibility for your use of the app, 
          when you’re using the app, it’s important to bear in mind that although we endeavor to ensure that it is updated 
          and correct at all times, we do rely on third parties to provide information to us so that we can make it 
          available to you. AFOREHAND Studio accepts no liability for any loss, direct or indirect, you experience as a 
          result of relying wholly on this functionality of the app.</p>

          <span><strong>Your obligations</strong></span>
          <p style={{ textIndent: "40px" }} >You’re not allowed to copy or modify the app, any part of the app, or our 
            trademarks in any way. You’re not allowed to attempt to extract 
            the source code of the app, and you also shouldn’t try to translate the app into other languages or make 
            derivative versions. </p>
          <p style={{ textIndent: "40px" }} >The app itself, and all trademarks, copyright and other intellectual property 
            rights related to it, still belong to AFOREHAND Studio.</p>    
          <p style={{ textIndent: "40px" }}> AFOREHAND Studio is committed to ensuring that the app is as useful and 
            efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its 
            services, at any time and for any reason. We will never charge you for the app or its services without 
            making it very clear to you exactly what you’re paying for.</p>

          <span><strong>Amendments</strong></span>
          <p style={{ textIndent: "40px" }} >At some point, we may wish to update the app. Thus, you are advised to review 
            this page periodically for any changes. We will notify you 
            of any changes by posting the new Terms and Conditions on this page.</p>
          <p style={{ textIndent: "40px" }} >This Terms and Conditions apply to any updates, enhancements, new features of 
            the <strong>Guardians Legion : TD</strong> app.</p>    
          <p style={{ textIndent: "40px" }} >Your continued use of the <strong>Guardians Legion : TD</strong>  app after 
          changes have been posted to the Terms and Conditions will constitute your acceptance of such changes.</p>

          <span><strong>This Terms and Conditions are effective as of 2024-10-22.</strong></span>
          <br />
          <span style={{ marginTop: "10px" }}><strong>Contacts</strong></span>
          <p>If you have any questions, concerns, or suggestions regarding this Terms 
            and Conditions, please contact us <i style ={{fontStyle: "italic" }} > <strong>support_td@aforehand.com.ua</strong></i>
          </p>
        
        </div>

      </main>
    );
  }
}